import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import {
	map,
	cloneDeep,
	clone,
	findIndex,
	find,
	isEmpty,
	each,
	toLower,
	get,
	head,
	nth,
	includes,
	chunk,
	compact,
	trim,
} from 'lodash';

import { withLoader } from '../../../Common/components/loader';
import { withCancelable } from '../../../Common/components/cancelable';
import { withError } from '../../../Common/components/error';
import { withBlock } from '../../../Common/components/block';
import { principalService, authenticationService, kvaasService } from '../../../Common/services';
import { DraggableUsersColumn } from '../../../Common/components/settings';
import { Notification } from '../../../Common/components/notifications';
import { kvaasResources } from '../../../Common/utilities';
import handleInvalidRevision from '../utils/invalidRevision';
import FooterComponent from 'components/settings/components/FooterComponent';

const requestKeys = {
	FETCH: 'fetch',
	SAVE: 'save',
};

const _requestChunkSize = 5;

class AccountDropdownOrder extends Component {
	constructor(props) {
		super(props);
		this.top = createRef();
		this.notification = createRef();

		this.state = {
			id: '',
			companyName: '',
			email: '',
			token: '',
			sortKeysAlphabetically: false,
		};
	}

	componentDidMount = async () => {
		this.subscription = principalService.subscribe(this.refreshKeys);
		this.props.showLoader(true);
		try {
			const [
				{ id, list: users },
				{
					attributes: { email },
					signInUserSession: {
						idToken: { jwtToken: token },
					},
				},
			] = await this.props.makePendingRequest(
				Promise.all([
					principalService.get(),
					authenticationService.getUser(),
					kvaasService.get({ ...kvaasResources.collapsedSettingsMessages, throwError: true }),
				]),
				requestKeys.FETCH
			);

			const newState = {
				token,
				email,
				id,
				originalUsers: cloneDeep(users),
				users,
				sortKeysAlphabetically: false,
			};
			this.setState(newState);
			this.props.showLoader(false);
		} catch (e) {
			if (this.props.handleError(e)) {
				this.props.handleKvaasLoadError();
				this.props.showLoader(false);
			}
		}
	};

	componentWillUnmount = () => {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	};

	setStateAsync = newState => new Promise(resolve => this.setState(newState, resolve));

	handleRefreshKeys = async () => {
		let refreshErr;
		try {
			await this.props.makePendingRequest(
				authenticationService.refreshKeys(this.state.token, this.state.email),
				requestKeys.SAVE
			);
		} catch (e) {
			refreshErr = this.props.handleError(e, { delayMessage: true });
			if (!refreshErr) {
				return;
			}
		}
		return refreshErr;
	};

	setInactiveKeyOrder = async (mid, index) => {
		let sortorder = 0;
		try {
			await authenticationService.validateGatewayKey(mid);
		} catch (e) {
			if (includes(toLower(e.message), 'inactive')) {
				sortorder = index + 9999;
			}
		}

		return sortorder;
	};

	save = async () => {
		const { users, sortKeysAlphabetically, email, token } = this.state;
		if (this.props.isLoading) {
			return;
		}
		if (isEmpty(users)) return this.props.handleBlockChange(false);
		this.props.showLoader(true);
		let refreshKeys = false;
		let refNum;
		let error;

		try {
			const userChunks = chunk(users, _requestChunkSize);
			for (const userChunk of userChunks) {
				const newUsers = await Promise.all(
					map(userChunk, async (user, index) => {
						const newUser = {
							...user,
							dba_alias: trim(user.dba_alias),
							sortorder: sortKeysAlphabetically ? await this.setInactiveKeyOrder(user.mid, index) : user.sortorder,
						};
						const originalUser = find(this.state.originalUsers, { mid: user.mid });
						if (newUser.sortorder === originalUser.sortorder && newUser.dba_alias === originalUser.dba_alias)
							return null;
						return newUser;
					})
				);
				const usersToUpdate = compact(newUsers);
				if (isEmpty(usersToUpdate)) continue;
				const response = await this.props.makePendingRequest(
					Promise.all([...map(usersToUpdate, user => authenticationService.save(user, email, token))]),
					requestKeys.SAVE
				);
				refreshKeys = true;
				refNum = head(response).xRefNum;
			}
		} catch (e) {
			error = this.props.handleError(e, { delayMessage: true });
			if (error) {
				refreshKeys = true;
			}
			if (!error) return;
		}

		if (refreshKeys) {
			const refreshError = await this.handleRefreshKeys();
			if (refreshError) {
				error = refreshError;
				refNum = error.ref;
			}
		}
		this.handleAnyChanged(error, refNum);

		this.props.handleBlockChange(false);
	};

	getDisabledSorOrders = users => {
		if (isEmpty(users)) return {};
		return {
			down: nth(users, -1).sortorder,
			up: head(users).sortorder,
		};
	};

	getModifier = (isPreview, isDisabled) => {
		return `${isDisabled ? ' is-disabled' : ''} ${isPreview ? '-active' : ''}`;
	};
	handleAnyChanged = (error, refNum) => {
		this.props.showLoader(false);

		if (error) {
			return error.show();
		}
		if (refNum) {
			const notification = get(this, 'notification.current');
			if (notification) {
				notification.addNotification({
					message: 'Account dropdown order updated',
					ref: refNum,
					success: true,
				});
			}
		}
	};

	refreshKeys = principal => {
		if (principal && principal.id && principal.list) {
			this.setState({ users: principal.list, originalUsers: cloneDeep(principal.list) });
		}
	};

	scrollToTop = () => {
		if (this.top.current) {
			this.top.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
		}
	};

	handleChange = event => {
		const { name, value } = event.target;
		const newState = cloneDeep(this.state);
		const changedIndex = findIndex(newState.users, { mid: name });
		const changedItem = clone(newState.users[changedIndex]);
		changedItem.dba_alias = value;
		changedItem.dirty = true;
		newState.users[changedIndex] = changedItem;
		this.setState(newState);
		this.props.handleBlockChange(true);
	};

	handleSort = ({ target: { checked } }) => {
		const users = map(this.state.users, (user, i) => ({
			...user,
			sortorder: i + 1,
			dirty: true,
		}));
		this.setState({
			sortKeysAlphabetically: checked,
			users,
		});
		this.props.handleBlockChange(true);
	};
	handleArrowColumnMove = (sourceId, sortOrder, moveUp) => {
		const targetId = this.getTargetId(sortOrder, moveUp);
		if (!targetId) return;
		this.moveColumn(sourceId, targetId);
	};
	getTargetId = (sortOrder, moveUp) => {
		const { users } = this.state;
		let targetId;

		if (moveUp) {
			targetId = get(find(users, ({ sortorder }) => sortorder === sortOrder - 1), 'mid', null);
		} else {
			targetId = get(find(users, ({ sortorder }) => sortorder === sortOrder + 1), 'mid', null);
		}
		return targetId;
	};

	moveColumn = (sourceId, targetId) => {
		this.pendingUpdateFunction = ({ users }) => {
			const sourceItem = find(users, { mid: sourceId });
			const sourceIndex = findIndex(users, { mid: sourceId });
			const targetIndex = findIndex(users, { mid: targetId });
			const newUsers = users.slice();
			newUsers.splice(sourceIndex, 1);
			newUsers.splice(targetIndex, 0, sourceItem);
			each(newUsers, (user, index) => {
				user.sortorder = index + 1;
				user.dirty = true;
			});
			return {
				users: newUsers,
			};
		};
		if (!this.requestedFrame) {
			this.requestedFrame = requestAnimationFrame(this.drawFrame);
		}
		this.props.handleBlockChange(true);
	};
	drawFrame = () => {
		this.setState(this.pendingUpdateFunction);
		this.pendingUpdateFunction = null;
		this.requestedFrame = null;
	};

	render() {
		const { sortKeysAlphabetically, users } = this.state;

		return (
			<React.Fragment>
				<div className="settings__header">
					<h3 className="settings__title">User Settings</h3>
					<h5>Account Dropdown Settings</h5>
				</div>

				<div ref={this.top}></div>

				<div className="table--draggable--acc-drop-settings__wrapper">
					<div className="table table--draggable table--draggable--acc-drop-settings">
						<div className="table--draggable__header">
							<div className="table--draggable__th">Account</div>
							<div className="table--draggable__th">Mid</div>
							<div className="table--draggable__th">Alias</div>
							<div className="table--draggable__th center">
								<i
									data-tooltip="Alias names are not listed on transaction receipts and cardholder statements; they are only displayed in your Portal account."
									className="icon icon--sml icon--regular--info datatooltip--down-left"
								></i>
							</div>
						</div>
						<DraggableUsersColumn
							items={users}
							disabledSortOrders={this.getDisabledSorOrders(users)}
							getModifier={this.getModifier}
							disable={sortKeysAlphabetically}
							sortDisabled={sortKeysAlphabetically}
							onChange={this.handleChange}
							onHover={this.moveColumn}
							handleArrowColumnMove={this.handleArrowColumnMove}
						/>
					</div>
				</div>
				<FooterComponent
					save={this.save}
					disabled={this.props.isLoading}
					isLoading={this.props.isLoading}
					hideReset={true}
				/>
				<Notification ref={this.notification} />
			</React.Fragment>
		);
	}
}

AccountDropdownOrder.propTypes = {
	handleError: PropTypes.func,
	makePendingRequest: PropTypes.func,
	showLoader: PropTypes.func,
	isLoading: PropTypes.bool,
	handleBlockChange: PropTypes.func,
	handleKvaasLoadError: PropTypes.func,
};

export default withError(withLoader(withCancelable(withBlock(AccountDropdownOrder))), handleInvalidRevision);
