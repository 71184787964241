import React, { Component, Fragment } from 'react';
import { arrayOf, object, bool, func } from 'prop-types';
import { map } from 'lodash';

import { invokeIfFunction } from '../../utilities';

class SectionActions extends Component {
	render() {
		const { actions, disableAll, addFieldExpanded, hideDelete, hideAdd, createActionRef } = this.props;
		return (
			<div className="card__header__actions" ref={ref => createActionRef(ref, addFieldExpanded)}>
				{map(actions, ({ icon, action, tooltip, disabled, className, activeClass, isAdd, separator }) => {
					if ((icon === 'delete' && hideDelete) || (isAdd && hideAdd)) return;
					return (
						<Fragment key={invokeIfFunction(icon)}>
							{separator == true ? <div className="card__header__actions__separator"></div> : ''}
							<button
								id={isAdd ? 'addButton' : 'actionsButton'}
								className={
									className ||
									`btn btn--icon btn--icon--lrg ${
										addFieldExpanded ? activeClass || '' : ''
									} datatooltip--auto datatooltip--no-wrap`
								}
								disabled={disableAll || invokeIfFunction(disabled)}
								data-tooltip={invokeIfFunction(tooltip)}
								type="button"
								onClick={action}
							>
								<i className={`icon icon--sml icon--${invokeIfFunction(icon)}`}></i>
							</button>
						</Fragment>
					);
				})}
			</div>
		);
	}
}

SectionActions.propTypes = {
	actions: arrayOf(object).isRequired,
	disableAll: bool,
	addFieldExpanded: bool,
	hideDelete: bool,
	hideAdd: bool,
	createActionRef: func,
};
export default SectionActions;
