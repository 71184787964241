import React, { createRef, Fragment } from 'react';
import {
	clone,
	cloneDeep,
	get,
	isEmpty,
	times,
	toLower,
	each,
	replace,
	startCase,
	isObject,
	padStart,
	map,
	last,
	filter,
	find,
	endsWith,
	startsWith,
	upperFirst,
	camelCase,
	split,
	findIndex,
	sumBy,
	every,
	some,
	sum,
	isEqual,
	differenceWith,
	includes,
	isBoolean,
	round,
	chunk,
} from 'lodash';
import { any, array, bool, func } from 'prop-types';
import NumberFormat from 'react-number-format';

import {
	checkIfCanadian,
	CurrencyMap,
	FormErrors,
	kvaasResources,
	mapConvenienceToCustom,
	screenSize,
} from '../../../Common/utilities';
import { maxAllowedSalesTaxTooltip, paymentMethod, transactionType } from '../../new-transaction/constants';
import {
	addConvenienceFee,
	addSalesTax,
	calculateConvenienceFee,
	calculateSalesTax,
	toCurrency,
	cleanObject,
	checkHasConvenienceFee,
} from '../../new-transaction/helpers';
import { NewTransaction } from '../../new-transaction/new-transaction';
import { createField, isValid, validators } from '../../../Common/fields';
import {
	authenticationService,
	customerService,
	kvaasService,
	principalService,
	transactionService,
} from 'common/services';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { Modal } from 'common/components/modal';
import BulkChargeCustomersPopupGrid from './BulkChargeCustomersPopupGrid';
import { BulkChargeColumns as Columns } from './bulk-charge-columns/BulkChargeColumns';

const requestKeys = {
	KVAAS: 'kvaas',
	FETCH: 'fetch',
};

const handleInvalidAccountSettings = error => {
	if (error && toLower(error.message) === 'invalid account settings (g3)') {
		return {
			...error,
			isApiError: false,
			displayMessage: "This account can't process gift cards",
		};
	}
	return error;
};

const transactionDetailsFieldLabels = ['email', 'invoice', 'description', 'poNumber'];
const customFieldLabels = times(19, i => `custom${i + 1}`);

class BulkChargeCustomersPopup extends NewTransaction {
	initialState = (props, permissions = {}) => {
		let cCTransactionType = transactionType.SALE;
		const amount = createField(
			cCTransactionType === transactionType.SAVE ? 0 : '',
			validators.if(validators.currency, () => true)
		);
		const description = createField(
			'',
			validators.if(validators.required, () => this.state.requiredFields.description)
		);
		const poNumber = createField('', validators.if(validators.required, () => this.state.requiredFields.poNumber));

		const customers = this.mapCustomerEmails(props.selectedRows);
		const transactionDetails = {
			description,
			customers,
			poNumber,
		};

		const customFields = this.createCustomFields();

		const type = paymentMethod.CC;

		return {
			amount,
			paymentMethod: type,
			isLoading: true,
			expandAll: false,
			expand: {
				transaction: false,
				custom: false,
			},
			sendCopy: false,
			transactionDetails,
			customFields,
			errors: {},
			isProcessing: false,
			expandView: true,
			customDisplayLabels: {},
			requiredFields: {},
			transactionHiddenFields: {},
			convenienceFees: {},
			salesTax: {},
			permissions,
			unapprovedTransaction: false,
			ccTotal: '',
			checkTotal: '',
			ccConvenienceFee: 0,
			checkConvenienceFee: 0,
			ccSalesTaxAmount: 0,
			checkSalesTaxAmount: 0,
			isCcSalesTaxAmountOverwritten: false,
			isCheckSalesTaxAmountOverwritten: false,
			includeCcConvenience: false,
			includeCheckConvenience: false,
			includeCcSalesTax: false,
			includeCheckSalesTax: false,
			showPopupDetails: false,
			isExpandableView: window.innerWidth >= screenSize.lrg,
			isConfirmModalOpen: false,
			transactionsToProcess: [],
			paymentMethods: [],
			data: null,
			filteredRows: [],
			columns: cloneDeep(Columns),
			defaultColumns: cloneDeep(Columns),
			isSubmitted: false,
			tooltipProps: {},
		};
	};

	constructor(props) {
		super(props);

		this.pageTopRef = createRef();
		this.popupRef = createRef();
		this.popupGridRef = createRef();

		const principal = principalService.get();
		const permissions = (principal && principal.idInfo && principal.idInfo.permissions) || {};

		this.state = this.initialState(props, permissions);
		this.state.principal = principal;
	}

	get hiddenFields() {
		const hideTransactionDetails = this.areAllFieldsHidden(transactionDetailsFieldLabels) || this.isAchDisabled;
		const hideCustomFields = this.areAllFieldsHidden(customFieldLabels) || this.isAchDisabled;
		return {
			hideTransactionDetails,
			hideCustomFields,
			hideAll: hideTransactionDetails && hideCustomFields,
		};
	}

	get visibleExpansions() {
		const {
			expand: { transaction, custom },
		} = this.state;
		const { hideTransactionDetails, hideCustomFields } = this.hiddenFields;
		const expand = {};
		if (!hideTransactionDetails) {
			expand.transaction = transaction;
		}
		if (!hideCustomFields) {
			expand.custom = custom;
		}
		return expand;
	}

	currencyCode = CurrencyMap.resolveCurrency();

	async componentDidMount() {
		window.addEventListener('resize', this.updateExpandableView);
		try {
			this.showLoader(true);
			const { makePendingRequest } = this.props;
			const [
				requiredFields,
				bulkChargeDefaultColumns,
				unmappedCustomDisplayLabels,
				unmappedHiddenFields,
				portalFlags,
				convenienceFees,
				salesTaxResponse,
			] = await makePendingRequest(
				kvaasService.get(
					kvaasResources.transactionRequiredFields,
					kvaasResources.bulkChargeReportDefaultColumns,
					kvaasResources.transactionDisplayLabels,
					kvaasResources.transactionHiddenFields,
					kvaasResources.portalFlags,
					kvaasResources.convenienceFees,
					kvaasResources.salesTax
				),

				requestKeys.KVAAS
			);
			const isCanadian = checkIfCanadian();
			this.mapDefaultValuesToColumns(get(bulkChargeDefaultColumns, 'data'));
			const salesTax = get(salesTaxResponse, 'data');
			const newState = {
				expand: clone(this.state.expand),
				customFields: cloneDeep(this.state.customFields),
				isCanadian,
			};

			await this.mapSelectedRows(get(convenienceFees, 'data', {}), salesTax);

			if (requiredFields && requiredFields.data) {
				const { data } = requiredFields;
				newState.requiredFields = data;
				this.expandSectionsWithRequiredFields(newState);
			}
			const { customDisplayLabels } = mapConvenienceToCustom(
				convenienceFees,
				unmappedHiddenFields,
				unmappedCustomDisplayLabels
			);
			if (customDisplayLabels && customDisplayLabels.data) {
				newState.customDisplayLabels = customDisplayLabels.data;
			}
			if (portalFlags && portalFlags.data) {
				newState.expandView = true;
				newState.sendCopy = !!portalFlags.data.sendCopy;
				newState.showEbtfsVoucherOption = !!portalFlags.data.showEbtfsVoucherOption;
			}
			if (convenienceFees && convenienceFees.data) {
				newState.convenienceFees = convenienceFees.data;
				newState.includeCcConvenience = !!newState.convenienceFees.enableConvenienceFee;
				newState.includeCheckConvenience = this.state.hasCheckTransactions;
				newState.convenienceBeforeSales = !!newState.convenienceFees.convenienceBeforeSales;
			}
			if (salesTax) {
				newState.salesTax = salesTax;
				newState.includeCcSalesTax = !!salesTax.enableSalesTax;
				newState.includeCheckSalesTax = !!salesTax.enableSalesTax && this.state.hasCheckTransactions;
			}
			this.props.mapCustomDisplayLabels(this.state.columns, unmappedCustomDisplayLabels);
			this.props.mapCustomDisplayLabels(this.state.defaultColumns, unmappedCustomDisplayLabels);
			this.mapColumns(newState);
			this.setState(newState);
		} catch (e) {
			if (e && !e.isCanceled) {
				//eslint-disable-next-line
				console.error(e);
			}
		}
		this.showLoader();
	}

	removeRow = ({ customerId }) => {
		const { filteredRows, data } = cloneDeep(this.state);
		const newData = filter(filteredRows, item => item.customerId !== customerId);

		this.setState({ filteredRows: newData, data: { ...data, xReportData: newData } });
	};

	mapCustomerEmails = selectedRows => {
		return map(selectedRows, row => ({
			id: row.customerId,
			email: createField(row.email || '', validators.if(validators.required, () => this.state.requiredFields.email)),
		}));
	};

	findColumnIndex = (columns, columnKey) => findIndex(columns, ({ key }) => key === columnKey);

	showHideConvenienceAndFeeColumns = newState => {
		const { isMultiAmount } = this.props;
		const { columns, defaultColumns } = this.state;
		const { includeCcSalesTax, includeCheckSalesTax, includeCcConvenience, includeCheckConvenience } = newState;
		const includeSalesTax = includeCcSalesTax || includeCheckSalesTax;
		const includeConvenience = includeCcConvenience || includeCheckConvenience;
		const amountIndex = this.findColumnIndex(columns, 'amount');

		if (includeSalesTax) {
			const taxColumnIndex = this.findColumnIndex(columns, 'salesTax');
			const defaultTaxColumnIndex = this.findColumnIndex(defaultColumns, 'salesTax');

			newState.columns[taxColumnIndex] = {
				...newState.columns[taxColumnIndex],
				visible: isMultiAmount,
			};
			newState.defaultColumns[defaultTaxColumnIndex] = {
				...newState.defaultColumns[defaultTaxColumnIndex],
				visible: isMultiAmount,
			};
		}

		if (includeConvenience) {
			const convFeeColumnIndex = this.findColumnIndex(columns, 'convenienceFee');
			const defaultConvFeeColumnIndex = this.findColumnIndex(defaultColumns, 'convenienceFee');
			this.hideCustom03Column(newState);

			newState.columns[convFeeColumnIndex] = {
				...newState.columns[convFeeColumnIndex],
				visible: isMultiAmount,
			};
			newState.defaultColumns[defaultConvFeeColumnIndex] = {
				...newState.defaultColumns[defaultConvFeeColumnIndex],
				visible: isMultiAmount,
			};
		}

		newState.columns[amountIndex] = {
			...newState.columns[amountIndex],
			visible: isMultiAmount,
		};
	};

	hideCustom03Column = newState => {
		const {
			columns,
			defaultColumns,
			convenienceFees: { convenienceCustomKey, originalCustomKey },
		} = newState;

		if (some([convenienceCustomKey, originalCustomKey], key => toLower(key) === 'custom03')) {
			const custom03ColumnIndex = this.findColumnIndex(columns, 'custom03');
			const defaultCustom03ColumnIndex = this.findColumnIndex(defaultColumns, 'custom03');

			newState.columns[custom03ColumnIndex] = {
				...newState.columns[custom03ColumnIndex],
				visible: false,
			};
			newState.defaultColumns[defaultCustom03ColumnIndex] = {
				...newState.defaultColumns[defaultCustom03ColumnIndex],
				visible: false,
			};
		}
	};

	mapColumns = newState => {
		const { columns, defaultColumns } = this.state;

		newState.columns = [...columns];
		newState.activeColumns = filter(columns, column => column.visible);
		newState.defaultColumns = [...defaultColumns];

		this.showHideConvenienceAndFeeColumns(newState);
		this.addRenderSelectAllCheckboxToColumns(newState);
	};

	mapDefaultValuesToColumns = defaultValues => {
		const { columns } = this.state;
		const customVisibleColumns = [];
		each(
			each(defaultValues, (value, key) => {
				if (value) {
					const defCol = find(columns, column => toLower(column.customSettingsKey) === toLower(key));
					if (!isEmpty(defCol)) {
						customVisibleColumns.push(key);
						defCol.visible = value;
					}
				}
			})
		);

		this.setState({ columns, customVisibleColumns });
	};

	addRenderSelectAllCheckboxToColumns = newState => {
		const {
			convenienceFees: { allowExclude: allowConvFeeExclude },
			salesTax: { allowExclude: allowSalesTaxExclude },
		} = newState;
		each(newState.columns, ({ displaySelectAllCheckbox, selectAllKey, key }, index) => {
			if (
				this.shouldAddSelectAllCheckbox({
					key,
					displaySelectAllCheckbox,
					allowSalesTaxExclude,
					allowConvFeeExclude,
				})
			) {
				this.addRenderSelectAllCheckbox(newState.columns, index, selectAllKey || key);
			}
		});
		each(newState.defaultColumns, ({ displaySelectAllCheckbox, selectAllKey, key }, index) => {
			if (
				this.shouldAddSelectAllCheckbox({
					key,
					displaySelectAllCheckbox,
					allowSalesTaxExclude,
					allowConvFeeExclude,
				})
			) {
				this.addRenderSelectAllCheckbox(newState.defaultColumns, index, selectAllKey || key);
			}
		});
	};

	addRenderSelectAllCheckbox = (columns, columnIndex, selectAllKey) => {
		columns[columnIndex] = {
			...columns[columnIndex],
			renderCheckbox: this.renderSelectAllCheckbox(selectAllKey),
			getColumnHeaderTooltip: this.getColumnHeaderTooltip(selectAllKey),
		};
	};

	shouldAddSelectAllCheckbox = ({ key, displaySelectAllCheckbox, allowSalesTaxExclude, allowConvFeeExclude }) =>
		(((key === 'salesTax' && allowSalesTaxExclude) || (key === 'convenienceFee' && allowConvFeeExclude)) &&
			displaySelectAllCheckbox) ||
		(displaySelectAllCheckbox && !some(['salesTax', 'convenienceFee'], item => item === key));

	mapSelectedRows = async (convenienceFees, salesTax) => {
		this.showLoader(true);
		try {
			const data = {
				xReportData: map(this.props.selectedRows, row => this.mapRow(row, convenienceFees, salesTax)),
			};
			data.xRecordsReturned = data.xReportData.length;

			this.mapData(data);

			const isPaymentMethodVisible = every(
				data.xReportData,
				({ paymentMethodDetails }) => !isEmpty(paymentMethodDetails)
			);

			const newState = {};

			if (!isPaymentMethodVisible) {
				const paymentMethods = await this.props.makePendingRequest(
					customerService.getPaymentMethods(),
					requestKeys.FETCH
				);
				newState.paymentMethods = paymentMethods.xReportData;
				customerService.mapCustomerPaymentMethods(data, paymentMethods);
			}

			newState.data = data;
			newState.filteredRows = cloneDeep(data.xReportData);
			newState.hasCheckTransactions = !isEmpty(
				find(newState.data.xReportData, row => toLower(row.paymentMethod) === 'check')
			);

			this.setState(newState);
		} catch (e) {
			this.props.handleError(e);
		}

		this.showLoader();
	};

	onInfoHover = (infoDimensions, tooltip) => {
		this.setState({ tooltipProps: { infoDimensions, tooltip } });
	};
	mapValuesToRows = (newData, newColumns) => {
		const { activeColumns } = cloneDeep(this.state);
		const newActiveColumns = filter(newColumns, column => column.visible);
		const oldActiveColumns = filter(activeColumns, column => column.visible);
		const diffColumns = differenceWith(newActiveColumns, oldActiveColumns, isEqual);
		const withoutDefaults = filter(
			diffColumns,
			column => !includes(['salestax', 'conveniencefee', 'sendreceipt', 'sendcopy'], toLower(column.key))
		);
		const { selectedRows } = this.props;
		each(newData.xReportData, (row, i) => {
			each(withoutDefaults, column => {
				const currentRow = find(selectedRows, r => r.customerId === row.customerId)[column.key];
				newData.xReportData[i][column.key] = currentRow;
			});
		});

		return newData;
	};

	mapRow = (
		row,
		{ allowExclude: allowConvFeeExclude, enableConvenienceFee },
		{ allowOverride: allowSalesTaxOverride, allowExclude: allowSalesTaxExclude, enableSalesTax }
	) => ({
		...row,
		amount: '',
		salesTax: 0,
		convenienceFee: 0,
		poNumber: '',
		customerNumber: '',
		invoice: '',
		description: '',
		sendReceipt: false,
		sendCopy: false,
		email: '',
		errors: {},
		gridRef: get(this.popupGridRef, 'current.gridRef.current', null),
		currency: this.currencyCode,
		handleChange: this.handleInputChange,
		isSalesTaxAllowed: this.checkIfRowSalesTaxAmountAllowed,
		onInfoHover: this.onInfoHover,
		popupRef: this.popupRef,
		feeAndTaxPermissions: {
			includeConvenience: enableConvenienceFee,
			includeSalesTax: enableSalesTax,
			allowConvFeeExclude,
			allowSalesTaxExclude,
			allowSalesTaxOverride,
		},
	});
	mapAdditionalColumns = () => {
		const { customVisibleColumns } = this.state;
		const availableColumns = {
			billMobile: '',
			billPhone: '',
			billFirstName: '',
			billLastName: '',
			billCompany: '',
		};
		each(availableColumns, (_, key) => {
			if (includes(customVisibleColumns, key)) {
				delete availableColumns[key];
			}
		});
		return availableColumns;
	};
	mapData = data => {
		const { isMultiAmount } = this.props;
		const {
			transactionDetails: { customers },
		} = this.state;

		let i = 0;
		if (data && data.xReportData && data.xReportData.length > 0) {
			each(data.xReportData, item => {
				const email = find(customers, customer => customer.id === item.customerId).email.value || '';
				item.email = email;
				item.isMultiAmount = isMultiAmount;
				item.gridRowNumber = i;
				item.index = i + 1;
				i++;
			});
		}
	};

	checkIfRowSalesTaxAmountAllowed = (value, amount) => {
		const maxAllowedAmount = round((amount || 0) * 0.2, 2);
		return value <= maxAllowedAmount;
	};

	getCustomData = data => {
		let cFields = {};
		times(19, i => {
			const oneBasedIndex = padStart(i + 2, 2, 0);
			const customKey = `customerCustom${oneBasedIndex}`;
			cFields[`custom${oneBasedIndex}`] = data[customKey];
		});
		return cFields;
	};

	handleGridChange = changes => {
		const newState = {};
		each(changes, ({ key, value }) => {
			if (key === 'data') {
				let data;
				if (key === 'data') {
					data = value;
				} else {
					data = this.state.data;
				}
				newState.filteredRows = data.xReportData;
			}
			newState[key] = value;
		});
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	expandSectionsWithErrors = newState => {
		newState.expand = { ...this.state.expand };

		if (!isEmpty(newState.errors.transactionDetails)) {
			newState.expand.transaction = true;
		}

		if (!isEmpty(newState.errors.customFields)) {
			newState.expand.custom = true;
		}
	};

	validateRequiredFields = ({ transactionDetails, customFields, email, sendReceipt }, errors) => {
		const { requiredFields, customDisplayLabels } = this.state;

		if (requiredFields) {
			if (!isValid(transactionDetails.description)) {
				errors.transactionDetails = errors.transactionDetails || {};
				errors.transactionDetails.description = `${customDisplayLabels.description || 'Description'} is required`;
			}
			each(customFields, (item, key) => {
				if (!isValid(item)) {
					errors.customFields = errors.customFields || {};
					const fieldCount = key.match(/(\d+)/);

					const label = !fieldCount
						? key
						: fieldCount[0] > 9
						? key[0].toUpperCase() + key.slice(1)
						: replace(startCase(key), /\s/g, '0');

					errors.customFields[key] = `${customDisplayLabels[key] || label} is required`;
				}
			});
		}

		this.validateMultiAmountEmail({ email, sendReceipt }, errors);
	};

	validateMultiAmountEmail = ({ email, sendReceipt }, errors) => {
		const { isMultiAmount } = this.props;
		const { customDisplayLabels } = this.state;

		if (isMultiAmount && sendReceipt) {
			if (!email) {
				errors.email = `${customDisplayLabels.email || 'Email'} is required`;
			} else if (!validators.email(email)) {
				errors.email = `${customDisplayLabels.email || 'Email'} is invalid`;
			}
		}
	};

	validateFields = data => {
		const { customDisplayLabels } = this.state;
		const errors = {};

		if (!validators.currency(data.amount)) {
			errors.amount = `A valid ${customDisplayLabels.amount || 'Amount'} is required`;
		}

		this.validateRequiredFields(data, errors);

		return errors;
	};

	checkFormValidity = () => {
		const { data } = this.state;
		const newState = { data: { ...data } };

		each(data.xReportData, ({ description, custom02, custom03, ...rest }, rowIndex) => {
			newState.data.xReportData[rowIndex] = {
				...data.xReportData[rowIndex],
				errors: this.validateFields({
					...rest,
					transactionDetails: { description },
					customFields: { custom02, custom03 },
				}),
			};
		});

		newState.filteredRows = cloneDeep(newState.data.xReportData);

		const allErrors = map(newState.filteredRows, ({ errors }) => errors);

		this.condenseAllErrors(newState, allErrors);

		this.setState(newState);

		return every(newState.filteredRows, ({ errors }) => isEmpty(errors));
	};

	checkRowsValidityIfSubmitted = () => {
		if (this.state.isSubmitted) {
			this.checkFormValidity();
		}
	};

	condenseAllErrors = (newState, allErrors) => {
		const errors = {};
		each(allErrors, errObj => {
			each(errObj, (message, fieldKey) => {
				const errorKey = `${fieldKey}${message.indexOf('required') > -1 ? 'Required' : 'Invalid'}`;
				errors[errorKey] = message;
			});
		});

		newState.errors = errors;
	};

	addRowFeesAndTaxes = (data, amount, salesTaxAmount, feeAndTaxPermissions) => {
		const {
			salesTax,
			salesTax: { enableSalesTax },
			convenienceFees,
		} = this.state;
		let { includeSalesTax, includeConvenience } = feeAndTaxPermissions;

		const paymentMethodType = data.paymentMethodType;
		data.xAmount = clone(amount);
		if (!this.props.isMultiAmount) {
			if (paymentMethodType === paymentMethod.CC) {
				includeConvenience = this.state.includeCcConvenience;
				includeSalesTax = this.state.includeCcSalesTax;
			} else {
				includeConvenience = this.state.includeCheckConvenience;
				includeSalesTax = this.state.includeCheckSalesTax;
			}
		}
		if (includeSalesTax && enableSalesTax && salesTaxAmount) {
			addSalesTax(data, salesTaxAmount);
			data.tax = salesTaxAmount;
		}

		addConvenienceFee(this.state.convenienceBeforeSales || !includeSalesTax ? amount : amount + (data.tax || 0), data, {
			convenienceFees,
			salesTax,
			paymentMethod: paymentMethodType,
			includeSalesTax,
			includeConvenience,
			amount: { value: amount },
		});

		this.convertGatewayKeysToRecurring(cleanObject(data));
	};

	addFeesAndTaxes = data => {
		const {
			salesTax,
			salesTax: { enableSalesTax, salesTaxPercentage },
			convenienceBeforeSales,
			convenienceFees,
			amount: { value: amount },
		} = this.state;
		const paymentMethodType = data.paymentMethodType;
		const isSalesTaxAmountOverwritten = this.state[`is${upperFirst(paymentMethodType)}SalesTaxAmountOverwritten`];
		const includeSalesTax = this.state[`include${upperFirst(paymentMethodType)}SalesTax`];
		const includeConvenience = this.state[`include${upperFirst(paymentMethodType)}Convenience`];
		const salesTaxAmount = this.state[`${paymentMethodType}SalesTaxAmount`];
		data.xAmount = clone(amount);

		if (includeSalesTax && enableSalesTax) {
			const salesTaxAmountValue = isSalesTaxAmountOverwritten
				? salesTaxAmount
				: calculateSalesTax(
						convenienceBeforeSales && includeConvenience
							? amount + calculateConvenienceFee(amount, convenienceFees, paymentMethodType)
							: amount,
						salesTaxPercentage || 0
				  );
			if (salesTaxAmountValue) {
				addSalesTax(data, salesTaxAmountValue);
				data.tax = salesTaxAmountValue;
			}
		}

		addConvenienceFee(convenienceBeforeSales || !includeSalesTax ? amount : amount + (data.tax || 0), data, {
			convenienceFees,
			salesTax,
			paymentMethod: paymentMethodType,
			includeSalesTax,
			includeConvenience,
			amount: { value: amount },
		});

		this.convertGatewayKeysToRecurring(cleanObject(data));
	};

	convertGatewayKeysToRecurring = data => {
		each(data, (value, key) => {
			if (startsWith(key, 'x')) {
				data[camelCase(key.substr(1))] = value;
				delete data[key];
			}
		});
	};

	renderMessages = msg => {
		return (
			<div className="list--notification--item">
				<span className="spc--right--sml">{msg[0]}</span>
				<span>{msg[1]}</span>
			</div>
		);
	};

	onSubmit = async e => {
		const { isProcessing, data, columns } = cloneDeep(this.state);
		const { makePendingRequest } = this.props;

		if (e && e.preventDefault) {
			e.preventDefault();
		}

		if (isProcessing) {
			return;
		}

		const newState = cloneDeep(this.state);
		newState.isSubmitted = true;
		each(newState, item => {
			if (isObject(item)) {
				if (item.dirty !== undefined) {
					item.dirty = true;
				} else {
					each(item, field => {
						if (isObject(field)) {
							if (field.dirty !== undefined) {
								field.dirty = true;
							}
						}
					});
				}
			}
		});
		await this.setStateAsync(newState);
		if (!this.checkFormValidity()) {
			this.scrollToTop();
			return;
		}

		this.setState({ isConfirmModalOpen: true, isLoading: true }, this.props.hideModal());

		try {
			let paymentMethods = this.state.paymentMethods;
			if (isEmpty(paymentMethods)) {
				const { xReportData } = await makePendingRequest(customerService.getPaymentMethods(), requestKeys.FETCH);
				paymentMethods = xReportData;
				this.setState({ paymentMethods });
			}
			this.revertInvisibleColumnValues(data.xReportData, columns);

			const transactionsToProcess = map(data.xReportData, item => {
				const {
					billFirstName,
					billLastName,
					billCompany,
					billMobile,
					billPhone,
					customerId,
					paymentMethod: customerPaymentMethod,
					salesTax,
					amount,
					feeAndTaxPermissions,
					description,
					invoice,
					PONumber,
					email,
					sendReceipt,
					sendCopy,
				} = item;
				const loweredCasedPaymentMethod = toLower(customerPaymentMethod);
				const customData = this.getCustomData(item);
				let paymentMethodType = loweredCasedPaymentMethod;
				if (loweredCasedPaymentMethod === 'credit') {
					paymentMethodType = 'cc';
				}

				const transactionData = {
					paymentMethodType,
					customerId,
					allowDuplicates: true,
					billFirstName,
					billLastName,
					billCompany,
					billMobile,
					billPhone,
					invoice,
					PONumber,
					description,
					email,
					sendCopy,
					feeAndTaxPermissions,
					...customData,
				};

				if (sendReceipt) {
					transactionData.custReceipt = true;
				}

				this.addRowFeesAndTaxes(transactionData, amount, salesTax, feeAndTaxPermissions);

				return transactionData;
			});
			this.setState({ transactionsToProcess });
		} catch (e) {
			this.handleError(e);
			this.closeConfirmModal();
		}
		this.showLoader();
	};
	joinMessage = (responses, processedPayments) => {
		const expiredMessages = [];
		const succeededMessages = [];
		each(responses, (response, i) => {
			const message = processedPayments[i]
				? `${processedPayments[i].maskedNumber}\r\n(RefNum: ${response.gatewayRefNum})`
				: `${response.gatewayRefNum}`;
			const messageWithFullName = `${message} ${response.fullName}`;
			if (toLower(response.gatewayStatus) === 'approved') {
				succeededMessages.push(`${messageWithFullName}`);
			} else {
				expiredMessages.push(`${messageWithFullName}`);
			}
		});
		const displayExpiredMessage = map(expiredMessages, message => {
			const displayMessageArray = split(message, ' ');
			return this.renderMessages(displayMessageArray);
		});
		const displaySuccessMessage = map(succeededMessages, message => {
			const displayMessageArray = split(message, ' ');
			return this.renderMessages(displayMessageArray);
		});

		return (
			<div>
				{!isEmpty(displayExpiredMessage) && (
					<div className="">
						<label className="type--color--error type--base display--b spc--bottom--tny">Declined:</label>
						{displayExpiredMessage}
					</div>
				)}
				{!isEmpty(displaySuccessMessage) && (
					<div className="type--check type--check--inner">
						<label className="type--color--success display--b type--base spc--bottom--tny">Approved:</label>
						{displaySuccessMessage}
					</div>
				)}
			</div>
		);
	};
	revertInvisibleColumnValues = (transactionsToProcess, columns) => {
		each(transactionsToProcess, transaction => {
			each(columns, column => {
				if (
					!column.visible &&
					column.key !== 'amount' &&
					column.key !== 'convenienceFees' &&
					column.key !== 'salesTax'
				) {
					transaction[column.key] = '';
				}
			});
		});
	};
	getCustomerFullName = (customerId, data) => {
		const customer = find(data, { customerId });
		return `${get(customer, 'billFirstName', '')} ${get(customer, 'billLastName', '')}`;
	};
	processTransactions = async () => {
		try {
			this.showLoader(true);
			this.setState({ isProcessing: true });
			const { sendCopy, transactionsToProcess, data } = cloneDeep(this.state);
			const { addNotification, selectedRows, refreshGridData, isMultiAmount } = this.props;
			const user = await authenticationService.getUser();
			const username = user && user.attributes && user.attributes.email;
			const chunks = chunk(transactionsToProcess, 10);
			let responses = [];
			for (let transactionsChunk of chunks) {
				const chunkResponses = await Promise.all(
					map(
						transactionsChunk,
						async ({ paymentMethodType, sendCopy: rowSendCopy, feeAndTaxPermissions, ...rest }) => {
							const tData = { ...rest };
							const response = await customerService.processTransaction(tData);
							if (response) {
								response.fullName = this.getCustomerFullName(tData.customerId, data.xReportData);
							}
							if (sendCopy || rowSendCopy) {
								await transactionService.sendReceipt(username, response.gatewayRefNum);
							}
							return response;
						}
					)
				);
				responses = [...responses, ...chunkResponses];
			}
			if (some(responses, response => response.gatewayErrorMessage)) {
				const processedPayments = map(transactionsToProcess, transaction => {
					return find(this.state.paymentMethods, pm => pm.paymentMethodId === transaction.paymentMethodId);
				});
				const isApiError = true;
				const message = this.joinMessage(responses, processedPayments);

				this.handleError({ message, isApiError });
				this.closeConfirmModal();
				return;
			}
			this.props.enableDisableSidebar(false);
			this.showLoader(false);

			addNotification({
				message: `Customer${
					(isMultiAmount ? data.xReportData.length : selectedRows.length) > 1 ? 's' : ''
				} charged successfully`,
				success: true,
				ref: get(last(responses), 'refNum'),
				onClose: refreshGridData,
			});
		} catch (e) {
			this.closeConfirmModal();
			this.handleError(e);
			this.showLoader(false);
			return;
		}
		this.props.closeModal();
	};

	closeConfirmModal = () => this.setState({ isConfirmModalOpen: false }, this.props.unhideModal);

	showLoader = (isLoading = false) => this.setState({ isLoading });

	handleInputChange = (customerId, { target: { value, checked, name, type } }) => {
		const { data } = this.state;
		const newState = { data: { ...data } };
		const rowIndex = findIndex(data.xReportData, row => row.customerId === customerId);

		if (rowIndex === -1) {
			return;
		}

		const [fieldKey] = split(name, '.');

		if (fieldKey === 'includeConvenience' || fieldKey === 'includeSalesTax') {
			this.handleIncludeChange(newState, rowIndex, fieldKey, checked);
		} else if (fieldKey === 'amount') {
			this.handleRowAmountChange(newState, rowIndex, value);
		} else if (fieldKey === 'salesTax') {
			this.handleRowSalesTaxAmountChange(newState, rowIndex, value);
		} else {
			newState.data.xReportData[rowIndex] = {
				...data.xReportData[rowIndex],
				[fieldKey]: type === 'checkbox' ? checked : value,
			};
		}

		newState.filteredRows = cloneDeep(newState.data.xReportData);

		this.setState(newState, () => {
			this.checkRowsValidityIfSubmitted();
			const gridRef = get(this.popupGridRef, 'current.gridRef.current', null);
			if (!gridRef) {
				return;
			}
		});
	};

	handleIncludeChange = (newState, rowIndex, fieldKey, value) => {
		const { isMultiAmount } = this.props;
		if (isMultiAmount) {
			newState.data.xReportData[rowIndex] = {
				...newState.data.xReportData[rowIndex],
				feeAndTaxPermissions: {
					...newState.data.xReportData[rowIndex].feeAndTaxPermissions,
					[fieldKey]: value,
				},
			};
			this.handleRowAmountChange(newState, rowIndex, newState.data.xReportData[rowIndex].amount);
		} else {
			each(newState.data.xReportData, row => {
				row.feeAndTaxPermissions[fieldKey] = value;
			});
		}
	};

	handleRowSalesTaxAmountChange = (newState, rowIndex, value) => {
		const { isMultiAmount } = this.props;
		const { convenienceFees, convenienceBeforeSales } = this.state;
		const { includeConvenience, includeSalesTax } = get(newState, `data.xReportData[${rowIndex}].feeAndTaxPermissions`);
		const amount = get(newState, `data.xReportData[${rowIndex}].amount`);
		const floatValue = value ? value : 0;
		const paymentMethodType = toLower(
			get(newState, `data.xReportData[${rowIndex}].paymentMethodDetails.tokenType`, '')
		);
		const convenienceFee = includeConvenience
			? calculateConvenienceFee(
					convenienceBeforeSales || !includeSalesTax ? amount : parseFloat(amount + floatValue).toFixed(4),
					convenienceFees,
					paymentMethodType
			  )
			: 0;

		if (isMultiAmount) {
			newState.data.xReportData[rowIndex] = {
				...newState.data.xReportData[rowIndex],
				salesTax: value,
				convenienceFee,
			};
		} else {
			each(newState.data.xReportData, row => {
				row.salesTax = value;
				row.convenienceFee = convenienceFee;
			});
		}
	};

	handleRowAmountChange = (newState, rowIndex, value) => {
		const { isMultiAmount } = this.props;

		const { convenienceFees, salesTax, convenienceBeforeSales } = this.state;
		const { includeSalesTax, includeConvenience } = get(newState, `data.xReportData[${rowIndex}].feeAndTaxPermissions`);
		const floatValue = value ? value : 0;
		const paymentMethodType = toLower(
			get(newState, `data.xReportData[${rowIndex}].paymentMethodDetails.tokenType`, '')
		);
		const salesTaxPercentage = get(salesTax, 'salesTaxPercentage', 0);
		let convenienceFee = 0;
		let salesTaxAmount = 0;

		const amountWithConvenienceFee = parseFloat(
			floatValue + calculateConvenienceFee(floatValue, convenienceFees, paymentMethodType)
		);
		salesTaxAmount = includeSalesTax
			? calculateSalesTax(
					convenienceBeforeSales && includeConvenience ? amountWithConvenienceFee : floatValue,
					salesTaxPercentage
			  )
			: 0;
		convenienceFee = includeConvenience
			? calculateConvenienceFee(
					convenienceBeforeSales || !includeSalesTax ? floatValue : parseFloat(floatValue + salesTaxAmount).toFixed(4),
					convenienceFees,
					paymentMethodType
			  )
			: 0;
		if (isMultiAmount) {
			newState.data.xReportData[rowIndex] = {
				...newState.data.xReportData[rowIndex],
				amount: value,
				convenienceFee,
				salesTax: salesTaxAmount,
			};
		} else {
			each(newState.data.xReportData, row => {
				row.amount = value;
				row.convenienceFee = convenienceFee;
				row.salesTax = salesTaxAmount;
			});
		}
	};

	handleSelectAll = selectAll => ({ target: { name } }) => {
		const { data, filteredRows } = this.state;
		const newState = { data: { ...data }, filteredRows: [...filteredRows] };
		const [key, section] = split(name, '.');

		each(data.xReportData, (_, index) => {
			if (section) {
				newState.data.xReportData[index] = {
					...data.xReportData[index],
					[section]: {
						...data.xReportData[index][section],
						[key]: !selectAll,
					},
				};
			} else {
				newState.data.xReportData[index] = {
					...data.xReportData[index],
					[key]: !selectAll,
				};
			}
		});

		each(filteredRows, (_, index) => {
			if (section) {
				newState.filteredRows[index] = {
					...filteredRows[index],
					[section]: {
						...filteredRows[index][section],
						[key]: !selectAll,
					},
				};
			} else {
				newState.filteredRows[index] = {
					...filteredRows[index],
					[key]: !selectAll,
				};
			}
		});

		this.setState(newState, this.checkRowsValidityIfSubmitted);
	};

	handleChange = e => {
		const target = e.target;
		const { isMultiAmount } = this.props;
		const name = target.name;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		if (isObject(this.state[name])) {
			value = {
				...this.state[name],
				value,
				dirty: true,
			};
		}
		const newState = {
			[name]: value,
		};

		if (startsWith(name, 'include')) {
			this.calculateTotalAmount(newState);
		}
		this.setState(newState, () => {
			if (!isMultiAmount) {
				this.handleAmountChange({}, true);
			}
			this.checkFormValidity();
		});

		if (name === 'sameAsBilling' && value === this.state.expand.shipping) {
			this.onExpandShrink('shipping');
		}
	};

	calculateTotalAmount = newState => {
		const {
			amount: { value },
			convenienceFees,
			salesTax,
			ccSalesTaxAmount,
			checkSalesTaxAmount,
			isCcSalesTaxAmountOverwritten,
			isCheckSalesTaxAmountOverwritten,
			convenienceBeforeSales,
			filteredRows,
		} = this.state;
		const { isMultiAmount } = this.state;
		const {
			includeCcConvenience = this.state.includeCcConvenience,
			includeCheckConvenience = this.state.includeCheckConvenience,
			includeCcSalesTax = this.state.includeCcSalesTax,
			includeCheckSalesTax = this.state.includeCheckSalesTax,
		} = newState;
		const floatValue = isMultiAmount ? round(sumBy(filteredRows, r => round(r.amount, 2)), 2) : parseFloat(value || 0);

		if (includeCcSalesTax) {
			if (isCcSalesTaxAmountOverwritten) {
				newState.ccSalesTaxAmount = ccSalesTaxAmount;
			} else {
				const amountWithConvenienceFee = parseFloat(
					floatValue + calculateConvenienceFee(floatValue, convenienceFees, paymentMethod.CC)
				).toFixed(4);

				newState.ccSalesTaxAmount = calculateSalesTax(
					convenienceBeforeSales && includeCcConvenience ? amountWithConvenienceFee : floatValue,
					get(salesTax, 'salesTaxPercentage', 0)
				);
			}
		} else {
			newState.ccSalesTaxAmount = 0;
		}

		if (includeCheckSalesTax) {
			if (isCheckSalesTaxAmountOverwritten) {
				newState.checkSalesTaxAmount = checkSalesTaxAmount;
			} else {
				const amountWithConvenienceFee = parseFloat(
					floatValue + calculateConvenienceFee(floatValue, convenienceFees, paymentMethod.CHECK)
				).toFixed(4);

				newState.checkSalesTaxAmount = calculateSalesTax(
					convenienceBeforeSales && includeCheckConvenience ? amountWithConvenienceFee : floatValue,
					get(salesTax, 'salesTaxPercentage', 0)
				);
			}
		} else {
			newState.checkSalesTaxAmount = 0;
		}

		newState.ccConvenienceFee = includeCcConvenience
			? calculateConvenienceFee(
					convenienceBeforeSales || !includeCcSalesTax
						? floatValue
						: parseFloat(floatValue + newState.ccSalesTaxAmount).toFixed(4),
					convenienceFees,
					paymentMethod.CC
			  )
			: 0;
		newState.checkConvenienceFee = includeCheckConvenience
			? calculateConvenienceFee(
					convenienceBeforeSales || !includeCheckSalesTax
						? floatValue
						: parseFloat(floatValue + newState.checkSalesTaxAmount).toFixed(4),
					convenienceFees,
					paymentMethod.CHECK
			  )
			: 0;

		newState.ccTotal = this.calculateTotal(
			floatValue,
			newState.ccConvenienceFee,
			newState.ccSalesTaxAmount,
			includeCcSalesTax,
			includeCcConvenience
		);
		newState.checkTotal = this.calculateTotal(
			floatValue,
			newState.checkConvenienceFee,
			newState.checkSalesTaxAmount,
			includeCheckSalesTax,
			includeCheckConvenience
		);
	};

	calculateTotal = (floatValue, convFee, tax, includeTax, includeConvFee) => {
		return toCurrency((floatValue || 0) + (includeConvFee ? convFee || 0 : 0) + (includeTax ? tax || 0 : 0));
	};

	calculateRowsTotalAmount = (rows, paymentMethodType) => {
		const filteredRows = filter(
			rows,
			row => toLower(get(row, 'paymentMethodDetails.tokenType', '')) === paymentMethodType
		);
		const totalAmounts = map(
			filteredRows,
			({ amount, convenienceFee, salesTax, feeAndTaxPermissions: { includeSalesTax, includeConvenience } }) =>
				this.calculateTotal(amount, convenienceFee, salesTax, includeSalesTax, includeConvenience)
		);
		return round(sum(totalAmounts, totalAmount => round(totalAmount, 2)), 2);
	};

	handleSalesTaxAmountChange = type => ({ floatValue, value }) => {
		const { convenienceFees, amount, convenienceBeforeSales, data } = this.state;
		const amountFloatValue = toCurrency(amount.value);
		const paymentType = type === 'cc' ? 'credit' : 'check';
		let convenienceFee = this.state[`include${startCase(type)}Convenience`]
			? calculateConvenienceFee(
					convenienceBeforeSales || !this.state[`include${startCase(type)}SalesTax`]
						? amountFloatValue
						: parseFloat(amountFloatValue + floatValue).toFixed(4),
					convenienceFees,
					paymentMethod[type === 'cc' ? 'CC' : 'CHECK']
			  )
			: 0;
		const newState = {
			[`${type}SalesTaxAmount`]: floatValue,
			[`is${upperFirst(type)}SalesTaxAmountOverwritten`]: true,
			[`${type}ConvenienceFee`]: convenienceFee,
		};

		if (this.state[`include${startCase(type)}SalesTax`]) {
			newState[`${type}Total`] = toCurrency(amountFloatValue + convenienceFee + (floatValue || 0));
		}
		each(data.xReportData, row => {
			if (toLower(row.paymentMethod) === paymentType) {
				row.convenienceFee = convenienceFee;
				row.salesTax = floatValue;
			}
		});
		newState.data = data;
		newState.filteredRows = data.xReportData;
		this.setState(newState, () => {
			const inputRef = this[`${name}SalesTaxInputRef`];
			if (inputRef && !endsWith(value, '.')) {
				inputRef.blur();
				inputRef.focus();
			}
		});
	};

	handleAmountChange = ({ floatValue = 0, value = 0 }, isNotAmount) => {
		const {
			convenienceFees,
			salesTax,
			amount,
			includeCcConvenience,
			includeCheckConvenience,
			includeCcSalesTax,
			includeCheckSalesTax,
			convenienceBeforeSales,
			data,
		} = this.state;
		if (isBoolean(isNotAmount)) {
			value = amount.value;
			floatValue = amount.value;
		}
		const checkAmount = floatValue;
		const salesTaxPercentage = get(salesTax, 'salesTaxPercentage', 0);
		const ccAmountWithConvenienceFee = parseFloat(
			floatValue + calculateConvenienceFee(floatValue, convenienceFees, paymentMethod.CC)
		);
		const checkAmountWithConvenienceFee = parseFloat(
			floatValue + calculateConvenienceFee(floatValue, convenienceFees, paymentMethod.CHECK)
		);
		let ccSalesTaxAmount =
			includeCcSalesTax && value
				? calculateSalesTax(
						convenienceBeforeSales && includeCcConvenience ? ccAmountWithConvenienceFee : floatValue,
						salesTaxPercentage
				  )
				: 0;
		let checkSalesTaxAmount =
			includeCheckSalesTax && value
				? calculateSalesTax(
						convenienceBeforeSales && includeCheckConvenience ? checkAmountWithConvenienceFee : floatValue,
						salesTaxPercentage
				  )
				: 0;
		let ccConvenienceFee =
			includeCcConvenience && value
				? calculateConvenienceFee(
						convenienceBeforeSales || !includeCcSalesTax
							? floatValue
							: parseFloat(floatValue + ccSalesTaxAmount).toFixed(4),
						convenienceFees,
						paymentMethod.CC
				  )
				: 0;
		let checkConvenienceFee =
			includeCheckConvenience && value
				? calculateConvenienceFee(
						convenienceBeforeSales || !includeCheckSalesTax
							? floatValue
							: parseFloat(floatValue + checkSalesTaxAmount).toFixed(4),
						convenienceFees,
						paymentMethod.CHECK
				  )
				: 0;
		this.mapSingleAmountValueToRows(
			data,
			checkConvenienceFee,
			checkSalesTaxAmount,
			checkAmount,
			ccConvenienceFee,
			ccSalesTaxAmount,
			value
		);
		this.setState(
			{
				data,
				ccConvenienceFee,
				checkConvenienceFee,
				ccSalesTaxAmount,
				checkSalesTaxAmount,
				isCcSalesTaxAmountOverwritten: false,
				isCheckSalesTaxAmountOverwritten: false,
				ccTotal: toCurrency(floatValue + ccConvenienceFee + ccSalesTaxAmount),
				checkTotal: toCurrency(floatValue + checkConvenienceFee + checkSalesTaxAmount),
				amount: {
					...amount,
					value: parseFloat(value),
				},
			},
			() => {
				this.singleAmountCallback(value);
			}
		);
	};
	singleAmountCallback = value => {
		this.calculateTotalAmount({});
		this.checkFormValidity();
		if (this.amountRef && !endsWith(value, '.')) {
			this.amountRef.blur();
			this.amountRef.focus();
		}
	};
	mapSingleAmountValueToRows = (
		data,
		checkConvenienceFee,
		checkSalesTaxAmount,
		checkAmount,
		ccConvenienceFee,
		ccSalesTaxAmount,
		value
	) => {
		each(data.xReportData, row => {
			const loweredPaymentMethod = toLower(row.paymentMethod);
			if (toLower(loweredPaymentMethod) === paymentMethod.CHECK) {
				row.salesTax = checkSalesTaxAmount;
				row.convenienceFee = checkConvenienceFee;
				row.amount = parseFloat(checkAmount);
			} else {
				row.convenienceFee = ccConvenienceFee;
				row.salesTax = ccSalesTaxAmount;
				row.amount = parseFloat(value);
			}
		});
	};

	hasSingleAmountConvenienceFee = convenienceFees => {
		return (
			(!!get(convenienceFees, 'enableConvenienceFee') && checkHasConvenienceFee(convenienceFees, paymentMethod.CC)) ||
			checkHasConvenienceFee(convenienceFees, paymentMethod.CHECK)
		);
	};
	getColumnHeaderTooltip = name => () => {
		const { data } = this.state;
		const [key, section] = split(name, '.');
		const allSelected =
			!isEmpty(data.xReportData) &&
			every(data.xReportData, row => {
				if (row[section]) {
					return row[section][key];
				}
				return row[key];
			});

		return `${allSelected ? 'Uns' : 'S'}elect all`;
	};

	renderSelectAllCheckbox = name => () => {
		const { data } = this.state;
		const [key, section] = split(name, '.');
		const allSelected =
			!isEmpty(data.xReportData) &&
			every(data.xReportData, row => {
				if (row[section]) {
					return row[section][key];
				}
				return row[key];
			});

		return (
			<span
				className="spc--right--tny display--ib align--v--top"
				// data-tooltip={`${allSelected ? 'Uns' : 'S'}elect All`}
			>
				<input
					className="input--check input--check--no-label"
					tabIndex="-1"
					type="checkbox"
					name={name}
					id={name}
					value={allSelected}
					checked={allSelected}
					onChange={this.handleSelectAll(allSelected)}
					disabled={isEmpty(data.xReportData)}
				/>
				<label htmlFor={name}></label>
			</span>
		);
	};

	renderConfirmModal = () => {
		const {
			isConfirmModalOpen,
			isLoading,
			isProcessing,
			transactionsToProcess,
			convenienceFees: { convenienceCustomKey, originalCustomKey },
		} = this.state;
		const disabled = isLoading || isProcessing;
		const totals = {
			ccCount: 0,
			checkCount: 0,
			ccTotalAmount: 0,
			checkTotalAmount: 0,
			ccTotalSalesTax: 0,
			checkTotalSalesTax: 0,
			ccTotalConvenienceFee: 0,
			checkTotalConvenienceFee: 0,
			ccTotalOriginalAmount: 0,
			checkTotalOriginalAmount: 0,
		};

		each(transactionsToProcess, item => {
			const { paymentMethodType, amount, tax = 0 } = item;
			let includeConvenience = this.state[`include${upperFirst(paymentMethodType)}Convenience`];

			if (this.props.isMultiAmount) {
				includeConvenience = get(item, 'feeAndTaxPermissions.includeConvenience', false);
			}

			totals[`${paymentMethodType}Count`]++;
			totals[`${paymentMethodType}TotalAmount`] = toCurrency(totals[`${paymentMethodType}TotalAmount`] + amount);
			totals[`${paymentMethodType}TotalSalesTax`] = toCurrency(totals[`${paymentMethodType}TotalSalesTax`] + tax);

			if (includeConvenience) {
				totals[`${paymentMethodType}TotalConvenienceFee`] = toCurrency(
					totals[`${paymentMethodType}TotalConvenienceFee`] + item[camelCase(convenienceCustomKey)]
				);
				totals[`${paymentMethodType}TotalOriginalAmount`] = toCurrency(
					totals[`${paymentMethodType}TotalOriginalAmount`] + item[camelCase(originalCustomKey)]
				);
			}
		});

		const {
			ccCount,
			checkCount,
			ccTotalAmount,
			checkTotalAmount,
			ccTotalSalesTax,
			checkTotalSalesTax,
			ccTotalConvenienceFee,
			checkTotalConvenienceFee,
			ccTotalOriginalAmount,
			checkTotalOriginalAmount,
		} = totals;
		const hasCcTransactions = !!ccCount;
		const hasCheckTransactions = !!checkCount;

		return (
			<Modal
				isOpen={isConfirmModalOpen}
				onClose={this.closeConfirmModal}
				shouldCloseOnOverlayClick={false}
				overlayClassName="modal__overlay"
				className="modal__content"
			>
				<div>
					{isLoading ? (
						<div className="modal__body type--med">
							<div className="loader__holder grid-sidebar__loader">
								<div className="loader__spinner"></div>
							</div>
							<div className="type--wgt--medium type--center">Please wait</div>
						</div>
					) : (
						<Fragment>
							<div className="modal__body type--med">
								<p className="spc--bottom--med">Are you sure you want to process:</p>
								{hasCcTransactions && (
									<div className="display--b message message--default spc--bottom--sml">
										<div className="type--base type--wgt--bold padd--bottom--tny separator--grey1 spc--bottom--tny">
											{ccCount} {`Credit Card Transaction${ccCount > 1 ? 's' : ''}:`}
										</div>
										<div className="flex--tertiary">
											Total Amount:
											<span className="type--wgt--bold spc--left--sml">
												{this.currencyCode}
												{ccTotalAmount}
											</span>
										</div>
										{ccTotalSalesTax ? (
											<div className="flex--tertiary">
												Total Sales Tax:
												<span className="type--wgt--bold spc--left--sml">
													{this.currencyCode}
													{ccTotalSalesTax}
												</span>
											</div>
										) : null}
										{ccTotalConvenienceFee ? (
											<div className="flex--tertiary">
												Total Electronic Transfer Fee:
												<span className="type--wgt--bold spc--left--sml">
													{this.currencyCode}
													{ccTotalConvenienceFee}
												</span>
											</div>
										) : null}
										{ccTotalOriginalAmount ? (
											<div className="flex--tertiary">
												Total Original Amount:
												<span className="type--wgt--bold spc--left--sml">
													{this.currencyCode}
													{ccTotalOriginalAmount}
												</span>
											</div>
										) : null}
									</div>
								)}
								{hasCheckTransactions && (
									<div className="display--b message message--default spc--bottom--sml">
										<div className="type--base type--wgt--bold padd--bottom--tny separator--grey1 spc--bottom--tny">
											{checkCount} {`Check Transaction${ccCount > 1 ? 's' : ''}:`}
										</div>
										<div className="flex--tertiary">
											Total Amount:
											<span className="type--wgt--bold spc--left--sml">
												{this.currencyCode}
												{checkTotalAmount}
											</span>
										</div>
										{checkTotalSalesTax ? (
											<div className="flex--tertiary">
												Total Sales Tax:
												<span className="type--wgt--bold spc--left--sml">
													{this.currencyCode}
													{checkTotalSalesTax}
												</span>
											</div>
										) : null}
										{checkTotalConvenienceFee ? (
											<div className="flex--tertiary">
												Total Electronic Transfer Fee:
												<span className="type--wgt--bold spc--left--sml">
													{this.currencyCode}
													{checkTotalConvenienceFee}
												</span>
											</div>
										) : null}
										{checkTotalOriginalAmount ? (
											<div className="flex--tertiary">
												Total Original Amount:
												<span className="type--wgt--bold spc--left--sml">
													{this.currencyCode}
													{checkTotalOriginalAmount}
												</span>
											</div>
										) : null}
									</div>
								)}
							</div>
							<div className="modal__footer">
								<button onClick={this.processTransactions} disabled={disabled} className="btn btn--med btn--primary">
									Confirm
								</button>
							</div>
						</Fragment>
					)}
				</div>
			</Modal>
		);
	};

	renderPopupHeader = () => {
		return (
			<div className="modal__header">
				<h4>Bulk Charge</h4>
			</div>
		);
	};

	renderLoaderSpinner = () => (
		<div className="loader__holder">
			<div className="loader__spinner"></div>
		</div>
	);

	renderForm = () => {
		const { isLoading } = this.state;

		if (isLoading) {
			return this.renderLoaderSpinner();
		}

		return this.renderMultiAmountForm();
	};

	renderFormErrors = (className = '') =>
		!isEmpty(this.state.errors) && (
			<div className={className}>
				<FormErrors errors={this.state.errors} />
			</div>
		);

	renderCheckSalesTaxFields = amount => {
		const { salesTax, includeCheckSalesTax, checkSalesTaxAmount } = this.state;
		const saleTaxTitle = <span className="type--color--text spc--right--tny">Check Sales Tax:</span>;
		const saleTaxTooltip = (
			<i
				data-tooltip={maxAllowedSalesTaxTooltip}
				className="icon icon--tny icon--regular--info spc--right--tny datatooltip--w--200"
			></i>
		);
		const saleTaxInput = (
			<NumberFormat
				thousandSeparator=","
				decimalSeparator="."
				disabled={true}
				decimalScale={2}
				prefix={this.currencyCode}
				value={checkSalesTaxAmount || 0}
				displayType="text"
				className="type--color--text"
			/>
		);

		return (
			<div className="col col-sml-12 col-med-6">
				<div className="flex--primary spc--bottom--sml">
					{salesTax.allowExclude ? (
						<div className="flex--primary">
							<div>
								<input
									tabIndex="-1"
									type="checkbox"
									name="includeCheckSalesTax"
									id="includeCheckSalesTax"
									className="input--check"
									value={includeCheckSalesTax}
									checked={includeCheckSalesTax}
									onChange={this.handleChange}
								/>
								<label htmlFor="includeCheckSalesTax" className="datatooltip--top-right">
									{saleTaxTitle}
								</label>
							</div>
							{saleTaxTooltip}
							{!salesTax.allowOverride && saleTaxInput}
						</div>
					) : (
						<div>
							<label htmlFor="includeCheckSalesTax" className="datatooltip--no-wrap">
								{saleTaxTitle}
								{saleTaxTooltip}
								{!salesTax.allowOverride && saleTaxInput}
							</label>
						</div>
					)}
					{salesTax.allowOverride && (
						<div className="type--wgt--medium fullwidth">
							<NumberFormat
								getInputRef={el => {
									this.checkSalesTaxInputRef = el;
								}}
								className="input input--med spc--top--sml"
								thousandSeparator=","
								decimalSeparator="."
								decimalScale={2}
								prefix={this.currencyCode}
								value={checkSalesTaxAmount}
								inputMode="numeric"
								isAllowed={this.checkIfSalesTaxAmountAllowed}
								allowNegative={false}
								onValueChange={this.handleSalesTaxAmountChange(paymentMethod.CHECK)}
								disabled={!includeCheckSalesTax || !amount.value}
							/>
						</div>
					)}
				</div>
			</div>
		);
	};

	renderCreditSalesTaxFields = amount => {
		const { includeCcSalesTax, ccSalesTaxAmount, salesTax } = this.state;
		const creditCardSalesTooltip = (
			<i
				data-tooltip={maxAllowedSalesTaxTooltip}
				className="icon icon--tny icon--regular--info spc--right--tny datatooltip--w--200"
			></i>
		);
		const creditCardSaleTitle = <span className="type--color--text spc--right--tny">Credit Sales Tax:</span>;

		const creditCardSaleInput = (
			<NumberFormat
				thousandSeparator=","
				decimalSeparator="."
				disabled={true}
				decimalScale={2}
				prefix={this.currencyCode}
				value={ccSalesTaxAmount || 0}
				displayType="text"
				className="type--color--text"
			/>
		);
		return (
			<div className="col col-sml-12 col-med-6">
				<div className="flex--primary">
					{salesTax.allowExclude ? (
						<div className="flex--primary">
							<div>
								<input
									tabIndex="-1"
									type="checkbox"
									name="includeCcSalesTax"
									id="includeCcSalesTax"
									className="input--check"
									value={includeCcSalesTax}
									checked={includeCcSalesTax}
									onChange={this.handleChange}
								/>
								<label htmlFor="includeCcSalesTax">{creditCardSaleTitle}</label>
							</div>
							{creditCardSalesTooltip}
							{!salesTax.allowOverride && creditCardSaleInput}
						</div>
					) : (
						<label htmlFor="includeCcSalesTax" className="datatooltip--top-right">
							{creditCardSaleTitle}
							{creditCardSalesTooltip}
							{!salesTax.allowOverride && creditCardSaleInput}
						</label>
					)}
					{salesTax.allowOverride && (
						<div className="type--wgt--medium fullwidth">
							<NumberFormat
								getInputRef={el => {
									this.creditSalesTaxInputRef = el;
								}}
								className="input input--med spc--top--sml"
								thousandSeparator=","
								decimalSeparator="."
								decimalScale={2}
								prefix={this.currencyCode}
								value={ccSalesTaxAmount}
								inputMode="numeric"
								isAllowed={this.checkIfSalesTaxAmountAllowed}
								allowNegative={false}
								onValueChange={this.handleSalesTaxAmountChange(paymentMethod.CC)}
								disabled={!includeCcSalesTax || !amount.value}
							/>
						</div>
					)}
				</div>
			</div>
		);
	};
	renderConvenienceFields = () => {
		const {
			includeCcConvenience,
			ccConvenienceFee,
			hasCheckTransactions,
			includeCheckConvenience,
			checkConvenienceFee,
			convenienceFees,
		} = this.state;
		const dataTooltip = includeCheckConvenience ? 'Exclude' : 'Include';
		return (
			<div className="row">
				{this.rendeConvenienceCheckbox(
					convenienceFees,
					includeCcConvenience,
					ccConvenienceFee,
					dataTooltip,
					'Credit Electronic Transfer Fee',
					'includeCcConvenience'
				)}

				{hasCheckTransactions &&
					this.rendeConvenienceCheckbox(
						convenienceFees,
						includeCheckConvenience,
						checkConvenienceFee,
						dataTooltip,
						'Check Electronic Transfer Fee',
						'includeCheckConvenience'
					)}
			</div>
		);
	};
	rendeConvenienceCheckbox = (convenienceFees, checkboxValue, inputValue, tooltip, title, checkboxName) => {
		return (
			<div className="col col-sml-12 col-med-6">
				<div className="spc--right--sml spc--bottom--sml">
					{convenienceFees.allowExclude && (
						<input
							tabIndex="-1"
							type="checkbox"
							name={checkboxName}
							id={checkboxName}
							className="input--check"
							value={checkboxValue}
							checked={checkboxValue}
							onChange={this.handleChange}
						/>
					)}
					<label htmlFor={checkboxName}>
						<div className="flex--primary flex--nowrap flex--gap--tny">
							{title}:
							<i
								className="icon icon--tny icon--regular--info spc--right--tny datatooltip--w--200"
								data-tooltip="This field was previously referred to as 'Convenience Fee'"
							></i>
							<NumberFormat
								thousandSeparator=","
								decimalSeparator="."
								disabled={true}
								decimalScale={2}
								prefix={this.currencyCode}
								value={inputValue || 0}
								displayType="text"
							/>
						</div>
					</label>
				</div>
			</div>
		);
	};
	renderSingleAmountForm = () => {
		const { amount, customDisplayLabels, convenienceFees, salesTax, hasCheckTransactions } = this.state;
		const invalidClassName = 'is-invalid';

		const hasConvenienceFee = this.hasSingleAmountConvenienceFee(convenienceFees);

		const hasSalesTax = !!get(salesTax, 'enableSalesTax') && !!get(salesTax, 'salesTaxPercentage');
		const newTransactionClass = `bulk-charge__amount__wrapper${window.innerWidth >= 500 ? ' ignoreHeight' : ''}`;

		return (
			<div className={newTransactionClass}>
				<div className="form__group">
					<div className="form__group__header spc--bottom--xsml">
						<label className="form__group__label type--uppercase">{customDisplayLabels.amount || 'Amount'} </label>
						<span className="form__group__required" data-tooltip="Required">
							*
						</span>
					</div>
					<NumberFormat
						getInputRef={el => {
							this.amountRef = el;
						}}
						placeholder={`${this.currencyCode}0.00`}
						thousandSeparator=","
						decimalSeparator="."
						inputMode="decimal"
						allowNegative={false}
						decimalScale={2}
						prefix={this.currencyCode}
						onValueChange={this.handleAmountChange}
						value={amount.value}
						isAllowed={this.checkIfAmountAllowed}
						autoFocus={true}
						className={`bulk-charge__amount ${(!isValid(amount) && invalidClassName) || ''} ${
							amount.value.length > 6 ? 'is-reduced' : ''
						}`}
					/>
				</div>
				{(hasConvenienceFee || hasSalesTax) && (
					<Fragment>
						{hasConvenienceFee && this.renderConvenienceFields()}
						<div className="row">
							{hasSalesTax && this.renderCreditSalesTaxFields(amount)}
							{hasSalesTax && hasCheckTransactions && this.renderCheckSalesTaxFields(amount)}
						</div>
					</Fragment>
				)}
			</div>
		);
	};
	renderMultiAmountForm = () => {
		const { isMultiAmount } = this.props;

		const { data, filteredRows, columns, defaultColumns, tooltipProps, errors } = this.state;

		return (
			<div>
				{!isMultiAmount && this.renderSingleAmountForm()}
				<span ref={this.pageTopRef}></span>
				<BulkChargeCustomersPopupGrid
					ref={this.popupGridRef}
					errors={errors}
					data={data}
					columns={columns}
					defaultColumns={defaultColumns}
					filteredRows={filteredRows}
					popupRef={this.popupRef}
					handleInputChange={this.handleInputChange}
					handleChange={this.handleGridChange}
					tooltipProps={tooltipProps}
					mapExistingValuesToRows={this.mapValuesToRows}
					removeRow={this.removeRow}
				/>
			</div>
		);
	};

	renderPopupFooter = () => {
		const { isProcessing, filteredRows, hasCheckTransactions } = this.state;

		const ccTotal = this.calculateRowsTotalAmount(filteredRows, paymentMethod.CC);
		const checkTotal = this.calculateRowsTotalAmount(filteredRows, paymentMethod.CHECK);

		return (
			<div className={`modal__footer ${this.isExpanded ? 'is-expanded' : ''}`}>
				<div className="flex--primary flex--gap--tny spc--right--sml--alt">
					<div className="flex--primary flex--gap--tny type--color--text--secondary type--p3">
						<span>Credit:</span>
						<NumberFormat
							thousandSeparator=","
							decimalSeparator="."
							disabled={true}
							decimalScale={2}
							prefix={this.currencyCode}
							value={ccTotal || 0}
							displayType="text"
							className="type--p3--medium"
						/>
					</div>
					{hasCheckTransactions && (
						<Fragment>
							<span className="type--color--text--opaque">|</span>
							<div className="flex--primary flex--gap--tny type--color--text--secondary type--p3">
								<span>Check:</span>
								<NumberFormat
									thousandSeparator=","
									decimalSeparator="."
									disabled={true}
									decimalScale={2}
									prefix={this.currencyCode}
									value={checkTotal || 0}
									displayType="text"
									className="type--p3--medium"
								/>
							</div>
						</Fragment>
					)}
				</div>
				<button type="button" className="btn btn--med btn--primary" disabled={isProcessing} onClick={this.onSubmit}>
					Apply Bulk Payment
				</button>
			</div>
		);
	};

	render() {
		const { showPopupDetails, isConfirmModalOpen } = this.state;
		return (
			<Fragment>
				{isConfirmModalOpen ? (
					this.renderConfirmModal()
				) : (
					<div ref={this.popupRef}>
						{this.renderPopupHeader()}
						<div
							className={
								this.popupRef && this.popupRef.current
									? 'modal__body'
									: `modal__body ${this.isExpanded ? 'is-expanded' : ''} ${showPopupDetails ? 'is-visible' : ''}`
							}
							tabIndex="-1"
						>
							{this.renderForm()}
						</div>
						{this.renderPopupFooter()}
					</div>
				)}
			</Fragment>
		);
	}
}

BulkChargeCustomersPopup.propTypes = {
	selectedRows: array.isRequired,
	notificationRef: any.isRequired,
	refreshGridData: func.isRequired,
	closeModal: func.isRequired,
	makePendingRequest: func.isRequired,
	isMultiAmount: bool.isRequired,
};

export const WrappedBulkChargeCustomersPopup = withError(
	withCancelable(BulkChargeCustomersPopup),
	handleInvalidAccountSettings
);
