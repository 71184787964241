import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withLoader } from './../../../Common/components/loader';
import { withError } from './../../../Common/components/error';
import { withCancelable } from './../../../Common/components/cancelable';
import { paymentEngineSettingsService, principalService } from 'common/services';
import { groupBy, isEmpty, map, mapValues } from 'lodash';
import PaymentEngineCard from '../components/PaymentEngineCard';
import PaymentEngineComputerModal from '../components/PaymentEngineComputerModal';
import PaymentEngineNewComputerModal from '../components/PaymentEngineNewComputerModal';

class PaymentEngineComponent extends Component {
	constructor(props) {
		super(props);
		this.state = this.initialState;
	}

	async componentDidMount() {
		try {
			this.showLoader(true);
			await this.fetchPaymentEngines();
		} catch (e) {
			this.handleError(e);
		}
		this.showLoader(false);
	}
	fetchPaymentEngines = async () => {
		try {
			const paymentEngines = await this.props.makePendingRequest(paymentEngineSettingsService.list());
			const groupedEngines = groupBy(paymentEngines, engine => {
				return engine.split('_')[0];
			});

			const result = mapValues(groupedEngines, engines => {
				return engines.map(engine => engine.split('_')[1]);
			});
			this.setState({ engines: result });
		} catch (e) {
			if (e && !e.isCanceled) {
				this.handleError(e);
			}
		}
	};

	fetchComputerData = async (softwareName, computer) => {
		this.showLoader(true);
		try {
			const selectedUserData = await this.props.makePendingRequest(
				paymentEngineSettingsService.load([softwareName, computer])
			);
			this.setState({ selectedUserData });
		} catch (e) {
			if (e && !e.isCanceled) {
				this.handleError(e);
			}
		}
		this.showLoader(false);
	};
	get initialState() {
		return {
			principal: principalService.get(),
			editMode: false,
			engines: {},
			selectedUserData: null,
			isPreviewEditOpen: false,
			activeSoftware: null,
			activeComputer: null,
			isAddNewOpen: false,
		};
	}

	showLoader = this.props.showLoader;
	handleError = this.props.handleError;

	handleReloadData = async () => {
		await this.fetchPaymentEngines();
	};

	handleModalClose = () => {
		this.setState({
			isPreviewEditOpen: false,
			isAddNewOpen: false,
			selectedUserData: null,
			editMode: false,
			activeSoftware: null,
			activeComputer: null,
		});
	};
	setEditModal = (softwareName, computer) => async () => {
		await this.fetchComputerData(softwareName, computer);
		this.setState({
			isPreviewEditOpen: true,
			editMode: true,
			activeSoftware: softwareName,
			activeComputer: computer,
		});
	};
	setPreviewModal = (softwareName, computer) => async () => {
		await this.fetchComputerData(softwareName, computer);
		this.setState({ isPreviewEditOpen: true, editMode: false });
	};
	setNewComputerModal = softwareName => async () => {
		this.setState({ isAddNewOpen: true, activeSoftware: softwareName });
	};

	onSave = async (settings, software, computer) => {
		this.showLoader(true);

		try {
			await this.props.makePendingRequest(paymentEngineSettingsService.save(settings, software, computer));
			await this.fetchPaymentEngines();
			this.setState({ isPreviewEditOpen: false, selectedUserData: null, editMode: false });
		} catch (e) {
			if (e.message === 'Invalid: DDB_Revision not current. Reload settings and try again.') {
				e.message = 'Settings out of date, \nreload and try again';
			}
			this.handleError(e);
		}
		this.showLoader(false);
	};
	render = () => {
		const {
			selectedUserData,
			isPreviewEditOpen,
			activeSoftware,
			activeComputer,
			engines,
			editMode,
			isAddNewOpen,
		} = this.state;

		return (
			<div>
				{isEmpty(engines) ? (
					<div>No payment engines available</div>
				) : (
					<Fragment>
						<h3 className="settings__title">Account Settings</h3>
						<h5 className="spc--bottom--xlrg">Payment Engine Settings</h5>
						<div className="flex--tertiary flex--gap--med spc--bottom--lrg">
							<div>
								<h6 className="spc--bottom--tny">Softwares</h6>
								<p className="type--p4 type--color--text--light">
									Changes Require a restart and can take up to 24 hrs to propagate unless cache is deleted.
								</p>
							</div>
							<button className="btn btn--action btn--action--secondary" onClick={this.handleReloadData}>
								<i className="icon icon--sml icon--reload"></i>
							</button>
						</div>
						{map(engines, (computers, softwareName) => (
							<PaymentEngineCard
								key={softwareName}
								software={softwareName}
								computers={computers}
								isLoading={this.props.isLoading}
								setEditModal={this.setEditModal}
								setPreviewModal={this.setPreviewModal}
								addNewComputer={this.setNewComputerModal}
							/>
						))}
						<PaymentEngineComputerModal
							isOpen={isPreviewEditOpen}
							onClose={this.handleModalClose}
							data={selectedUserData}
							softwareName={activeSoftware}
							computer={activeComputer}
							onSave={this.onSave}
							isLoading={this.props.isLoading}
							editMode={editMode}
						/>
						<PaymentEngineNewComputerModal
							isOpen={isAddNewOpen}
							onClose={this.handleModalClose}
							data={selectedUserData}
							softwareName={activeSoftware}
							computer={activeComputer}
							onSave={this.onSave}
							isLoading={this.props.isLoading}
							editMode={editMode}
						/>
					</Fragment>
				)}
			</div>
		);
	};
}
PaymentEngineComponent.propTypes = {
	handleError: PropTypes.func,
	showLoader: PropTypes.func,
	makePendingRequest: PropTypes.func,
	isLoading: PropTypes.bool.isRequired,
};

export default withError(withLoader(withCancelable(PaymentEngineComponent)));
