import React from 'react';
import PropTypes from 'prop-types';
import { get, constant, find, memoize, merge, concat, map, cloneDeep } from 'lodash';

import Grid from './grid';
import { ReactDataGrid } from '../react-data-grid';
import ToolbarComponent from './toolbar';
import GridFooter from './grid-footer';
import { createPortal } from 'react-dom';
import ColumnFilterComponent from './column-filter';

const minColumnWidth = 75;

const defaultClasses = {
	content: '',
	wrapper: '',
	header: 'flex--tertiary',
	headerMenu: 'header__menu',
	headerMenuAction: 'header__menu__action',
	headerGroup: 'group--alt group--alt--auto align--v--middle header__btn__holder',
	title: 'header__breadcrumbs',
	print: 'reportprint__table',
	printWrapper: 'pos--rel',
	exportWrapper: 'pos--rel',
	gridHolder: 'react-grid',
	gridWrapper: 'grid__holder--override',
	filter: 'align--h--left spc--right--sml',
	filterContainer: 'filter__container',
	filterWrapper: 'filter',
};

class PopupGrid extends Grid {
	mergeClasses = memoize(classes => merge({}, defaultClasses, classes));

	onRowClick = (...params) => {
		const row = params[1];
		if (
			!this.props.isExpandable ||
			(row && !row.isExpandable) ||
			(!this.props.displayRowDetails && row && row.isDetails) ||
			params[0] < 0
		) {
			return;
		}
		const args = this.props.mapCellArgs(...params);
		if (args) {
			this.onCellExpand(args);
		}
		if (this.props.selectCustomer) {
			this.props.selectCustomer(row);
		}
	};
	onFilterColumns = async (col, updateDefColumns) => {
		this.oldVisibleColumns = concat(
			this.oldVisibleColumns,
			map(this.getDisplayedColumns(this.props.columns), ({ key }) => key)
		);
		const columns = map(this.props.columns, column => {
			const newColumn = find(col, { key: column.key });
			if (newColumn.visible === column.visible) {
				return column;
			}
			return {
				...column,
				visible: newColumn.visible,
			};
		});
		await this.calculateColumnWidths(null, columns);
		const changes = [
			{
				value: this.props.mapExistingValuesToRows(cloneDeep(this.props.data), columns),
				key: 'data',
			},
		];
		if (updateDefColumns) {
			changes.push({
				value: columns,
				key: 'defaultColumns',
			});
		}
		await this.props.onChange(changes);
	};

	getCellActions = (column, row) => {
		const cellActions = [
			{
				icon: <span className="icon icon--sml icon--delete cursor--pointer" />,
				callback: () => this.props.removeRow(row),
			},
		];
		return column.key === 'action' ? cellActions : null;
	};

	renderGridBody = () => {
		const {
			columns,
			filteredRows,
			useInlineFilters,
			type,
			expandInSidebar,
			rowDetailsProps,
			tooltipProps,
			defaultColumns,
		} = this.props;
		const { isLargeScreen } = this.state;
		return (
			<div
				className={`${this.classes.gridHolder} ${
					this.detailsRow && expandInSidebar && !isLargeScreen ? 'display--n' : ''
				}`}
				ref={this.gridHolderRef}
				style={{
					width: this.calculateGridWidth(this.contentRef.current),
				}}
			>
				<this.components.tooltip {...tooltipProps} />
				<ReactDataGrid
					minWidth={this.calculateGridWidth(this.contentRef.current) + (this.state.additionalPixel ? 1 : 0)}
					columns={this.getDisplayedColumns(columns)}
					defaultColumns={defaultColumns}
					rowsCount={filteredRows.length}
					rowGetter={this.rowGetter}
					onGridSort={this.onGridSort}
					ref={this.gridRef}
					toolbar={useInlineFilters ? ToolbarComponent : constant(null)}
					onAddFilter={this.handleInlineFilter}
					getValidFilterValues={this.getValidInlineFilterValues}
					onClearFilters={this.handleInlineFilter}
					emptyRowsView={this.renderEmptyGrid}
					onCellExpand={this.onCellExpand}
					onRowClick={this.onRowClick}
					onColumnResize={this.onColumnResize}
					minColumnWidth={minColumnWidth}
					enableRowSelect={null}
					rowScrollTimeout={null}
					rowHeight={40}
					enableCellSelect={false}
					getDetailsRowIndex={this.getDetailsRowIndex}
					rowRenderer={
						this.components.rowRenderer ? (
							<this.components.rowRenderer
								setDetailsRef={this.setRowDetailsRef}
								refreshGridData={this.refreshGridData}
								openModal={this.openCloseModal}
								gridHolder={this.gridHolderRef.current}
								resizeGrid={this.resizeGrid}
								type={type}
								rowDetailsRenderer={expandInSidebar ? null : this.components.rowDetails}
								rowDetailsProps={rowDetailsProps}
								showLoader={this.showLoader}
							/>
						) : (
							undefined
						)
					}
					getCellActions={this.getCellActions}
				/>
			</div>
		);
	};

	renderGridHeader = () => {
		const {
			showGridHeader,
			filterColumns,
			enablePrint,
			enableExport,
			enableViewInTransactionReport,
			useInlineFilters,
			renderGridHeaderViewInTransactionReport,
		} = this.props;

		return (
			showGridHeader && (
				<div className={this.classes.filterWrapper} ref={this.filtersContainerRef}>
					<div id="filterContainer" className={this.classes.filterContainer}>
						<div className={this.classes.header}>
							<div className={this.classes.filter}>{this.renderTitle()}</div>
							<div className={this.classes.gridHeader}>
								<div className={this.classes.headerGroup}>
									{filterColumns && <div className="spc--left--tny push" ref={this.columnFilterPortalRef} />}
									{enableViewInTransactionReport && renderGridHeaderViewInTransactionReport()}
									{enablePrint && this.renderPrintButton()}
									{enableExport && this.renderExportButton()}
									<this.components.gridHeader
										openCloseModal={this.openCloseModal}
										refreshGridData={this.refreshGridData}
									/>
								</div>
								{useInlineFilters && (
									<div className="filter__toggle">
										<button type="button" className="btn btn--med btn--secondary" onClick={this.showHideInlineFilters}>
											<i
												className={`icon icon--sml icon--search${
													this.gridRef.current && this.gridRef.current.state.canFilter ? '' : '--disabled'
												} icon--middle`}
											/>
										</button>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)
		);
	};

	render = () => {
		const {
			data,
			expandInSidebar,
			hasPaging,
			fetchingAdditionalData,
			fetchingData,
			filteredRows,
			type,
			hasMoreData,
			onLoadMoreLimitChange,
			loadMoreOptions,
			loadMoreLimit,
			showGridFooter,
			showColumnFilter,
			kvaasResourceType,
		} = this.props;

		const detailRow = find(data && data.xReportData, r => r.isDetails);
		const popupRef = get(this.props.popupRef, 'current', false);

		return (
			<div
				ref={this.contentRef}
				className={
					popupRef
						? ''
						: `${this.classes.content} ${
								this.gridRef.current && this.gridRef.current.state.canFilter
									? ''
									: 'grid__holder--override__header-one-row'
						  } ${this.detailsRow && expandInSidebar ? 'is-expanded' : ''}`
				}
			>
				<div>
					{this.renderGridHeader()}
					{this.renderGridBody()}
				</div>
				{expandInSidebar && detailRow && (
					<this.components.rowDetails
						key={detailRow.index}
						row={detailRow}
						visibleColumns={this.props.columns}
						openModal={this.openCloseModal}
						refreshGridData={this.refreshGridData}
						gridHolder={this.gridHolderRef.current}
						setDetailsRef={this.setRowDetailsRef}
						resizeGrid={this.resizeGrid}
						type={this.props.type}
						closeRow={() => this.onRowClick(detailRow.gridRowNumber, detailRow)}
						{...(detailRow.expandedRowProps || {})}
						{...this.props.rowDetailsProps}
					/>
				)}
				{showGridFooter && (
					<GridFooter
						gridFooterRef={this.gridFooterRef}
						isLoadMoreEnabled={hasPaging && hasMoreData(data)}
						fetchingAdditionalData={fetchingAdditionalData}
						fetchingData={fetchingData}
						filteredRows={filteredRows}
						type={type}
						kvaasResourceType={kvaasResourceType}
						onLoadMoreLimitChange={onLoadMoreLimitChange}
						loadMoreLimit={loadMoreLimit}
						loadMoreOptions={loadMoreOptions}
						openCloseModal={this.openCloseModal}
						CustomComponent={this.components.gridFooter}
					/>
				)}
				{this.columnFilterPortalRef.current &&
					showColumnFilter &&
					createPortal(
						<ColumnFilterComponent
							hideSettings={true}
							defaultColumns={this.props.defaultColumns}
							columns={this.props.columns}
							filteredColumns={this.onFilterColumns}
							header={this.components.columnFilterHeader}
							footer={this.components.columnFilterFooter}
							isDisabled={data === null}
							type={type}
							kvaasResourceType={kvaasResourceType}
							updateState={this.updateColumnFilterState}
							state={this.state.columnFilterState}
						/>,
						this.columnFilterPortalRef.current
					)}{' '}
			</div>
		);
	};
}

PopupGrid.propTypes = {
	renderGridFooterLoadAllResults: PropTypes.func,
	renderGridHeaderViewInTransactionReport: PropTypes.func,
	enableViewInTransactionReport: PropTypes.bool,
	showGridFooterLoadAllResults: PropTypes.bool,
};

export default PopupGrid;
