import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { PasswordInput } from 'common/components/password-input';
import { includes, some, toLower, toUpper, trim } from 'lodash';
import { Auth } from 'aws-amplify';
import { logger } from 'common/utilities';
const specialCharactersRegex = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

class ConfirmPasswordBody extends Component {
	constructor(props) {
		super(props);
	}

	setErrorMessage = errorMessage => {
		this.props.setComponentState({ errorMessage });
	};

	handleChange = event => {
		this.props.setComponentState({
			[event.target.name]: event.target.value,
		});
	};

	handleSubmit = async event => {
		event.preventDefault();
		if (!this.formValidation()) {
			return;
		}

		let { history, password, username, code, skipRedirect } = this.props;

		username = trim(toLower(username));
		code = trim(code);

		try {
			if (skipRedirect) {
				this.props.showLoader(true);
			}
			await Auth.forgotPasswordSubmit(username, code, password);
			if (!skipRedirect) {
				history.push({
					pathname: '/login',
					state: { username: username },
				});
			}
			if (skipRedirect) {
				this.props.notifyPasswordChange();
			}
		} catch (err) {
			logger.logError({
				message: 'Forgot password error.',
				errorDetails: err,
				username,
			});
			let message;
			switch (err && err.code) {
				case 'CodeMismatchException': {
					message = 'The provided code does not match the expected value.';
					break;
				}
				case 'ExpiredCodeException': {
					message = 'The provided code has expired.';
					break;
				}
				case 'InvalidParameterException': {
					message = err.message;
					break;
				}
				case 'UserNotFoundException': {
					message = 'User with provided email does not exist.';
					break;
				}
				case 'InvalidPasswordException': {
					if (includes(toLower(err.message), 'security')) {
						message = 'There may be a key word in your password that is not allowed. Please try another password';
					} else {
						message =
							'Your password needs to be at least 8 characters long and contain at least one uppercase character, one lowercase character, one special character and one number. The keyword "password" is not allowed.';
					}
					break;
				}
				case 'LimitExceededException': {
					message = 'Login attempts exceeded the limit. Please wait and try again later.';
					break;
				}
				default: {
					message = 'Something went wrong. Please try again.';
					break;
				}
			}

			this.props.setComponentState({
				errorMessage: message,
			});
		}
		if (skipRedirect) {
			this.props.showLoader(false);
		}
	};

	resendConfirmationCode = async () => {
		let { username, history } = this.props;
		username = trim(toLower(username));

		try {
			await Auth.forgotPassword(username);
		} catch (err) {
			logger.logError({
				message: 'Resend confirmation code error.',
				errorDetails: err,
				username,
			});
			let message;
			switch (err && err.code) {
				case 'UserNotConfirmedException': {
					history.push({
						pathname: '/confirm-registration',
						state: { username: username },
					});
					break;
				}
				case 'UserNotFoundException': {
					message = 'User with provided email does not exist.';
					break;
				}
				case 'LimitExceededException': {
					message = 'Login attempts exceeded the limit. Please wait and try again later.';
					break;
				}
				default: {
					message = 'Something went wrong. Please try again.';
					break;
				}
			}
			this.props.setComponentState({
				errorMessage: message,
			});
		}
	};

	formValidation = () => {
		const { username, password, password2, code } = this.props;

		if (username.length <= 0) {
			this.props.setComponentState({
				errorMessage: 'Please enter your email',
			});
			return false;
		}

		if (password.length <= 0 || password2.length <= 0) {
			this.props.setComponentState({
				errorMessage: 'Please enter your password',
			});
			return false;
		}

		if (code.length <= 0) {
			this.props.setComponentState({
				errorMessage: 'Please enter code',
			});
			return false;
		}

		if (password !== password2) {
			this.props.setComponentState({
				errorMessage: 'Passwords do not match',
			});
			return false;
		}

		if (password.length < 8) {
			this.props.setComponentState({
				errorMessage: 'Your password needs to contain at least 8 characters',
			});
			return false;
		}

		if (!some(password, char => char === toUpper(char))) {
			this.props.setComponentState({
				errorMessage: 'Your password needs to contain at least one uppercase character',
			});
			return false;
		}

		if (!some(password, char => char === toLower(char))) {
			this.props.setComponentState({
				errorMessage: 'Your password needs to contain at least one lowercase character',
			});
			return false;
		}

		if (!some(password, char => !isNaN(parseInt(char)))) {
			this.props.setComponentState({
				errorMessage: 'Your password needs to contain at least one number',
			});
			return false;
		}

		if (!specialCharactersRegex.test(password)) {
			this.props.setComponentState({
				errorMessage: 'Your password needs to contain at least one special character',
			});
			return false;
		}

		return true;
	};

	redirectToLogin = e => {
		e.preventDefault();
		const { history } = this.props;
		history.push('/login');
	};

	redirectToForgotPassword = () => {
		const { history } = this.props;
		history.push('/forgot-password');
	};

	render = () => {
		const {
			errorMessage,
			username,
			code,
			password,
			password2,
			hideBackButton,
			hideEmail,
			hideNewPasswordHeader,
		} = this.props;
		return (
			<form className="auth__form" onSubmit={this.handleSubmit}>
				{/* In order to trick chrome into not prefilling our inputs we have to add fake inputs and hide them away from users ... https://bugs.chromium.org/p/chromium/issues/detail?id=914451#c66*/}
				<input
					type="password"
					style={{ position: 'absolute', left: 9999999999999999999999999999999, display: 'none' }}
				></input>
				<input
					type="username"
					style={{ position: 'absolute', left: 9999999999999999999999999999999, display: 'none' }}
				></input>
				<div
					className="form__group"
					style={{ position: 'absolute', left: 9999999999999999999999999999999, display: 'none' }}
				>
					<label className="form__group__label">
						<span className="type--none">Email </span>
					</label>
					<input name="code" type="text" className="input input--med" placeholder="000000" tabIndex="0" />
				</div>

				{!hideNewPasswordHeader && <h2 className="auth__form__title spc--bottom--xxxlrg">Set new password</h2>}
				{!hideEmail && (
					<div className="form__group">
						<div className="form__group__header">
							<span className="form__group__label">Email</span>
						</div>
						<input
							name="username"
							disabled={true}
							type="email"
							className="input input--med"
							placeholder="user@gmail.com"
							value={username}
							onChange={this.handleChange}
							tabIndex="0"
							inputMode="email"
						/>
					</div>
				)}
				<div className="form__group">
					<div className="form__group__header">
						<span className="form__group__label">Email with code has been sent</span>
						<span className="form__group__required" data-tooltip="Required">
							*
						</span>
					</div>
					<input
						name="code"
						type="text"
						className="input input--med"
						placeholder="000000"
						value={code}
						onChange={this.handleChange}
						tabIndex="0"
					/>
				</div>

				<p className="type--p4 spc--bottom--med">
					Your password needs to contain at least 8 characters, 1 upper case character, 1 number and 1 special
					character.
				</p>
				<div className="form__group">
					<div className="form__group__header">
						<span className="form__group__label">New Password</span>
						<span className="form__group__required" data-tooltip="Required">
							*
						</span>
					</div>
					<PasswordInput
						value={password}
						onChange={this.handleChange}
						tabIndex="0"
						setErrorMessage={this.setErrorMessage}
					/>
				</div>

				<div className="form__group">
					<div className="form__group__header">
						<span className="form__group__label">Confirm Password</span>
						<span className="form__group__required" data-tooltip="Required">
							*
						</span>
					</div>
					<PasswordInput
						placeholder="Confirm your password"
						name="password2"
						value={password2}
						onChange={this.handleChange}
						tabIndex="0"
						setErrorMessage={this.setErrorMessage}
					/>
					{errorMessage ? <div className="type--color--error">{errorMessage}</div> : null}
				</div>

				<button type="submit" className="btn btn--med btn--primary spc--bottom--sml" tabIndex="0">
					Save new password
				</button>
				<button
					type="button"
					className="btn btn--med btn--secondary spc--bottom--sml"
					onClick={this.resendConfirmationCode}
					tabIndex="0"
				>
					Resend code
				</button>
				{!hideBackButton && (
					<Fragment>
						<button
							type="button"
							className="btn btn--secondary btn--med spc--bottom--sml"
							onClick={this.redirectToForgotPassword}
							tabIndex="0"
						>
							Back
						</button>

						<div className="auth__form__create-pass spc--top--xlrg">
							<p>Do you have an account?</p>{' '}
							<button onClick={this.redirectToLogin} className="btn btn--sml btn--link">
								Log in
							</button>
						</div>
					</Fragment>
				)}
			</form>
		);
	};
}

ConfirmPasswordBody.propTypes = {
	errorMessage: PropTypes.string,
	username: PropTypes.string,
	code: PropTypes.string,
	password: PropTypes.string,
	password2: PropTypes.string,
	notifyPasswordChange: PropTypes.func,
	location: PropTypes.any,
	history: PropTypes.any,
	setComponentState: PropTypes.func,
	hideBackButton: PropTypes.any,
	skipRedirect: PropTypes.any,
	showLoader: PropTypes.func,
	hideEmail: PropTypes.bool,
	hideNewPasswordHeader: PropTypes.bool,
};

export default ConfirmPasswordBody;
