import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withCancelable } from 'common/components/cancelable';
import { withError } from 'common/components/error';
import { principalService } from 'common/services';
import { hasFeaturePackage } from 'common/utilities';
import { featurePackages } from 'common/utilities/has-feature-package';
import { SolaAccountSettingsComponent } from 'components/settings/pages';
import UserSettings from 'components/user-settings/UserSettings';
import { withRouter } from 'react-router-dom';
import sectionKeys from 'routing/sections';
import Route from 'routing/Route';
import AccountManagement from 'components/account-management/AccountManagement';

class SettingsContainer extends React.Component {
	constructor(props) {
		super(props);

		const principal = principalService.get();

		this.state = {
			principal,
			isFraud: principal.hasAccess && principal.hasAccess[sectionKeys.fraud],
			isViewOnly: principal.isViewOnly,
		};
	}

	get hasTerminalOnly() {
		return hasFeaturePackage(featurePackages.terminalOnly);
	}

	componentDidMount = async () => {
		const { principal } = this.state;
		try {
			this.subscription = principalService.subscribe(principal => {
				this.setState({ principal });
			});

			this.setState({
				principal,
			});
		} catch (e) {
			this.props.handleError(e);
		}
	};

	componentWillUnmount() {
		this.subscription.unsubscribe();
	}
	hasPageAccess(sectionKey) {
		if (this.state.principal && this.state.principal.hasAccess) {
			return this.state.principal.hasAccess[sectionKeys[sectionKey]];
		}
	}

	render() {
		const { match } = this.props;
		return (
			<Fragment>
				<Route
					path={this.hasTerminalOnly ? `${match.path}/user-settings/new-transaction` : `${match.path}/user-settings`}
					component={UserSettings}
				/>
				{this.hasPageAccess(sectionKeys.settings) && (
					<Fragment>
						{(this.state.isFraud || !this.state.isViewOnly) && (
							<Route path={`${match.path}/account-settings`} component={AccountManagement} />
						)}
					</Fragment>
				)}
				{this.hasPageAccess(sectionKeys.solaAccountSettings) && (
					<Fragment>
						{!this.state.isFraud && (
							<Route path={`${match.path}/sola-account-settings`} component={SolaAccountSettingsComponent} />
						)}
					</Fragment>
				)}
			</Fragment>
		);
	}
}
SettingsContainer.propTypes = {
	handleError: PropTypes.func,
	match: PropTypes.object,
};
export default withError(withCancelable(SettingsContainer, withRouter));
