import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
	orderBy,
	toLower,
	each,
	noop,
	last,
	dropRight,
	camelCase,
	flatMap,
	filter,
	clone,
	sumBy,
	memoize,
	endsWith,
} from 'lodash';
import { ExportToCsv } from 'export-to-csv';

import { CardAmountCountComponent, CurrencyComponent } from '../../../Common/components/columns/formatters';
import { CardTypeImagePath, CurrencyMap } from '../../../Common/utilities';
import { exportOptions } from '../../../Common/components/export/export-options';
import { ReactDataGrid } from '../../../Common/components/react-data-grid';
import { WhiteRenderer } from '../row';
import { ColumnHeader } from 'common/components/columns/headers';

class BreakdownGrid extends Component {
	constructor(props) {
		super(props);
		const columns = this.createColumns(props.columns);
		this.state = {
			data: null,
			gridHeight: null,
			defaultColumns: clone(columns),
			columns,
		};
	}

	get cardTypes() {
		return [
			'Visa',
			'MC',
			'MasterCard',
			'Discover',
			'Amex',
			'EBT',
			'EBTW',
			'EWic',
			'Diners',
			'JCB',
			'Gift',
			'Check',
			'Unknown',
			'Debit',
			'Pledger',
			'DonorsFund',
			'OJC',
		];
	}

	createColumns = (columns = this.props.columns) => {
		const { seperateCountColumn } = this.props;

		return [
			{
				key: 'cardType',
				name: 'Card Type',
				sortable: true,
				headerRenderer: <ColumnHeader />,
				formatter: ({ value }) =>
					value !== 'Grand Total' ? (
						<div className="flex--primary flex--gap--sml--alt flex--nowrap">
							<img src={CardTypeImagePath.getPath(value)} className="grid__creditcard" alt={`${value} card`} />
							<div>{value}</div>
						</div>
					) : (
						value
					),
				visible: true,
			},
			...flatMap(columns, transactionType => [
				{
					key: camelCase(`${transactionType}Count`),
					name: `${transactionType} Count`,
					visible: seperateCountColumn,
					sortable: true,
					headerRenderer: <ColumnHeader />,
				},
				{
					key: camelCase(`${transactionType}Amount`),
					name: seperateCountColumn ? 'Total Amount' : transactionType,
					sortable: true,
					formatter: props =>
						seperateCountColumn ? (
							<CurrencyComponent isBolded={true} {...props} />
						) : (
							<CardAmountCountComponent {...props} />
						),
					getRowMetaData: ({ [camelCase(`${transactionType}Count`)]: count, currency }) =>
						seperateCountColumn ? { seperateCountColumn, currency } : count,
					visible: true,
					headerRenderer: <ColumnHeader />,
					alignHeaderRight: true,
				},
			]),
		];
	};

	componentDidMount = () => {
		window.addEventListener('resize', this.setGridSizes);
		const data = this.getData();
		this.setState(
			{
				data,
			},
			() => {
				this.setGridSizes();
				this.props.disableExport();
			}
		);
	};

	componentDidUpdate = ({ totals }) => {
		if (totals !== this.props.totals) {
			this.setGridSizes();
		}
	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.setGridSizes);
	};

	getGridHeight = data => {
		const rowCount = this.rowsCount(data);
		const columnHeight = 48;
		const scrollSize = 16;
		const headerSize = 35;
		const canvasMargin = window.innerWidth >= 1200 ? 7 : window.innerWidth >= 768 ? 13 : 11;
		const offset =
			canvasMargin +
			(window.innerWidth >= 1200
				? headerSize
				: window.innerWidth >= 768
				? headerSize + scrollSize
				: scrollSize + headerSize);
		const height = rowCount * columnHeight + offset;
		const minGridContainerHeight = 204;

		return Math.max(minGridContainerHeight, height);
	};

	getGridWidth = () => {
		const visibleColumns = this.getDisplayedColumns(this.state.columns);
		const widths = sumBy(visibleColumns, 'width') + 6;
		return widths || undefined;
	};

	setGridHeight = () => {
		this.setState({
			gridHeight: this.getGridHeight(),
		});
	};

	setGridSizes = () => {
		this.setGridHeight();
	};

	getData = () => {
		const { totals, displayTotal, columns, displayCurrency } = this.props;
		const data = [];

		each(this.cardTypes, cardType => {
			if (displayCurrency) {
				each(CurrencyMap.map, (_, currency) => {
					if (totals[camelCase(`${cardType}TotalCount`) + currency]) {
						const row = { cardType };
						each(columns, transactionType => {
							row[camelCase(`${transactionType}Amount`)] =
								parseFloat(totals[camelCase(`${cardType}${transactionType}Amount`) + currency].toFixed(2)) || 0;
							row[camelCase(`${transactionType}Count`)] =
								totals[camelCase(`${cardType}${transactionType}Count`) + currency] || 0;
							row.currency = currency;
						});
						data.push(row);
					}
				});
			} else {
				if (totals[camelCase(`${cardType}TotalCount`)]) {
					const row = { cardType };
					each(columns, transactionType => {
						row[camelCase(`${transactionType}Amount`)] =
							parseFloat(totals[camelCase(`${cardType}${transactionType}Amount`)].toFixed(2)) || 0;
						row[camelCase(`${transactionType}Count`)] = totals[camelCase(`${cardType}${transactionType}Count`)] || 0;
					});
					data.push(row);
				}
			}
		});

		if (displayTotal || (data.length > 1 && columns.length > 1)) {
			data.push({
				cardType: 'Grand Total',
				...totals,
			});
		}

		return data;
	};

	rowsCount = (data = this.state.data) => {
		const rows = data || [];
		return rows.length;
	};

	rowGetter = i => {
		const t = this.state.data[i];

		if (t != null) {
			return t;
		}
	};

	getDisplayedColumns = memoize(columns => filter(columns, ({ visible }) => visible));

	renderEmptyGrid = () => {
		return (
			<Fragment>
				<div className="react-grid-Empty-title">0 Results</div>
				<p className="react-grid-Empty-text">{this.props.emptyMessage}</p>
			</Fragment>
		);
	};

	onGridSort = (sortKey, sortDirection) => {
		let data = clone(this.state.data);
		if (sortDirection === 'NONE') {
			data = this.getData();
		} else {
			const lastElement = last(data);
			if (this.props.displayTotal) {
				data = dropRight(data, 1);
			}
			data = orderBy(data, [item => item[sortKey]], [toLower(sortDirection)]);
			if (this.props.displayTotal) {
				data.push(lastElement);
			}
		}

		this.setState({ data });
	};

	getColumns = columns => {
		let returnObj = [];
		for (let col of columns) {
			returnObj[col.key] = col.name.replace(/\u00AD/g, '');
		}
		return returnObj;
	};

	replaceColumns = (data, columnsSource) => {
		const { displayCurrency } = this.props;
		const columns = this.getColumns(columnsSource);
		if (displayCurrency) {
			columns.currency = 'Currency';
		}
		const mappedData = [];
		for (let item of data) {
			let newObj = {};
			const columnKeys = Object.keys(columns);
			for (let colKey of columnKeys) {
				const newPropName = columns[colKey];
				if (endsWith(toLower(colKey), 'amount')) {
					newObj[newPropName] = `${displayCurrency ? CurrencyMap.resolveCurrency(item.currency) : '$'}${item[colKey]}`;
				} else {
					newObj[newPropName] = item[colKey];
				}
			}
			mappedData.push(newObj);
		}
		return mappedData;
	};

	download = filename => {
		const { dateRange } = this.props;
		const properties = { filename, ...exportOptions };
		if (dateRange) {
			properties.showTitle = true;
			properties.title = dateRange;
		}
		const exporter = new ExportToCsv(properties);
		const data = this.replaceColumns(this.state.data, this.state.columns);
		exporter.generateCsv(data);
	};

	render = () => {
		const { gridHeight, columns } = this.state;

		return (
			<div className="react-grid">
				<ReactDataGrid
					minWidth={this.getGridWidth()}
					minHeight={gridHeight}
					columns={this.getDisplayedColumns(columns)}
					rowsCount={this.rowsCount()}
					rowGetter={this.rowGetter}
					emptyRowsView={this.renderEmptyGrid}
					rowRenderer={<WhiteRenderer />}
					onGridSort={this.onGridSort}
					rowHeight={40}
					enableRowSelect={null}
					rowScrollTimeout={null}
				/>
			</div>
		);
	};
}

BreakdownGrid.defaultProps = {
	disableExport: noop,
};

BreakdownGrid.propTypes = {
	totals: PropTypes.object.isRequired,
	displayTotal: PropTypes.bool,
	columns: PropTypes.array,
	displayCurrency: PropTypes.bool,
	seperateCountColumn: PropTypes.bool,
	dateRange: PropTypes.string,
	disableExport: PropTypes.func.isRequired,
	emptyMessage: PropTypes.string,
};

export default BreakdownGrid;
