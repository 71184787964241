import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { map, every, transform, camelCase, each, find, isEmpty, includes, split, size } from 'lodash';

import { Modal } from './../../modal';
import { roles, permissions, permissionsPerRole } from './constants';
import principalService from '../../../services/principalService';
import { withError } from '../../error';
import { ReactMultiEmail, isEmail } from '../../react-multi-email';

class AddEditUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isModalOpen: false,
			showAdditionalRole: false,
			isEmailTab: true,
			emailBlocked: false,
		};
	}

	handleOpenModal = async () => {
		try {
			const { user } = this.props;
			const additionalRoles = find(roles, { key: 'additionalRoles' });
			const additionalRoleKeys = map(additionalRoles.roles, role => role.key);
			const principal = principalService.get();
			const emailBlocked = principal.idInfo.xBlockSsoKeySetupViaEmail;
			let groupName = '';
			const isEmailTab = user ? !includes(user.xEmail, ':') : !emailBlocked;

			if (!user && includes(principal.id, ':')) {
				groupName = split(split(principal.id, ':')[0], 'sso_')[1];
			}

			const role = user && user.xRole ? camelCase(user.xRole) : '';
			const newState = {
				isModalOpen: true,
				role,
				emails: [],
				groupName,
				userName: '',
				isValidInput: false,
				isEmailTab,
				emailBlocked,
			};
			if (user && user.key2) {
				const mappedResponse = await this.props.mapResponseToPermissions(user.key2);
				if (!mappedResponse) {
					return;
				}
				const { keyType, ddbRevision, index1, keySettings } = mappedResponse;
				newState.keytype = keyType;
				newState.ddbrevision = ddbRevision;
				newState.index1 = index1;
				newState.keySettings = keySettings;
			}
			this.setState(newState);
			if (additionalRoleKeys.includes(newState.role)) {
				this.toggleAdditionalRoles();
			}
		} catch (e) {
			this.props.handleError(e);
		}
	};

	handleCloseModal = () => {
		this.setState({ isModalOpen: false });
	};

	handleChange = ({ target: { name, value } }) => {
		this.setState({
			[name]: value,
		});
	};

	saveChanges = () => {
		const { key2 } = this.props.user;
		const { role, ddbrevision, index1, keytype, keySettings } = this.state;
		const user = { key2, role, ddbrevision, index1, keytype, keySettings };
		this.props.saveChanges(user, this.handleCloseModal);
	};

	createEmailUsers = (newUsers, baseUser) => {
		const emails = [...this.state.emails];

		each(emails, email => {
			const newUser = {
				...baseUser,
				email,
			};

			newUsers.push(newUser);
		});
	};

	createSsoUsers = (newUsers, baseUser) => {
		const { groupName, userName } = this.state;
		newUsers.push({
			...baseUser,
			ssoprovider: groupName,
			email: userName,
		});
	};

	createNewUser = () => {
		const { role, isEmailTab } = this.state;
		const principal = principalService.get();
		const index1 = principal && principal.idInfo.xMerchantID;
		const newUsers = [];
		const baseUser = {
			role,
			index1,
			keytype: 'sso',
		};

		if (isEmailTab) {
			this.createEmailUsers(newUsers, baseUser);
		} else {
			this.createSsoUsers(newUsers, baseUser);
		}

		this.props.createNewUser(newUsers, this.handleCloseModal);
	};

	handleRoleChange = e => {
		const { name } = e.target;
		this.setState({
			role: name,
		});
	};

	checkPermission = e => {
		this.setState({
			permissions: {
				...this.state.permissions,
				[e.target.name]: e.target.checked,
			},
		});
	};

	setAllToValue = (value, role) => {
		return transform(
			permissions,
			(acc, { key }) => {
				if (role && permissionsPerRole[role]) {
					acc[key] = permissionsPerRole[role][key] === undefined ? false : permissionsPerRole[role][key];
				} else {
					acc[key] = value;
				}
			},
			{}
		);
	};

	checkAll = value => {
		const newPermissions = this.setAllToValue(value);
		this.setState({
			permissions: newPermissions,
		});
	};

	setEmail = ({ emails, inputValue, isValidInput }) => {
		const newState = {
			isValidInput,
			inputValue,
		};
		if (emails) {
			newState.emails = emails;
		}
		this.setState(newState);
	};

	getLabel = (email, index, removeEmail) => {
		return (
			<div data-tag key={index}>
				<span>{email}</span>
				<span data-tag-handle onClick={() => removeEmail(index)}></span>
			</div>
		);
	};

	switchTab = isEmailTab => {
		this.setState({ isEmailTab });
	};

	toggleAdditionalRoles = () => {
		this.setState({
			showAdditionalRole: !this.state.showAdditionalRole,
		});
	};

	renderRoles = () => {
		const { role, showAdditionalRole } = this.state;

		return (
			<div className="">
				<h6 className="spc--bottom--lrg">Role</h6>
				<div className="flex--primary flex--column flex--gap--xlrg flex--top spc--bottom--med">
					{map(roles, (item, key) => {
						if (item.key === 'additionalRoles') {
							return null;
						}

						return (
							<div key={key}>
								<div className="spc--bottom--sml">
									<input
										type="radio"
										className="input--radio"
										id={item.key}
										name={item.key}
										value={item.key}
										checked={role === item.key}
										onChange={this.handleRoleChange}
									/>
									<label htmlFor={item.key}>{item.title}</label>
								</div>
								<p className="type--p4 type--color--text--light spc--left--lrg">{item.desc}</p>
							</div>
						);
					})}
					<button className="btn btn--link" onClick={this.toggleAdditionalRoles}>
						<i className={`icon icon--sml icon--${showAdditionalRole ? 'minus' : 'add'}--primary spc--right--sml`}></i>
						<span>Additional roles</span>
					</button>
					{showAdditionalRole && this.renderAdditionalRoles()}
				</div>
			</div>
		);
	};

	renderAdditionalRoles = () => {
		const { role } = this.state;
		const additionalRoles = find(roles, { key: 'additionalRoles' });

		return (
			<Fragment>
				{map(additionalRoles.roles, (item, key) => {
					return (
						<div key={key} className="">
							<div className="spc--bottom--sml">
								<input
									type="radio"
									className="input--radio"
									id={item.key}
									name={item.key}
									value={item.key}
									checked={role === item.key}
									onChange={this.handleRoleChange}
								/>
								<label htmlFor={item.key}>{item.title}</label>
							</div>
							<div className="type--p4 type--color--text--light spc--left--lrg">{item.desc}</div>
						</div>
					);
				})}
			</Fragment>
		);
	};

	renderUserGroup = () => {
		const { groupName, userName } = this.state;
		const { user } = this.props;

		return (
			<div>
				<div className="form__group">
					<div className="form__group__header">
						<span className="form__group__label">Group Name</span>
						<span className="form__group__required" data-tooltip="Required">
							*
						</span>
					</div>
					{this.props.type === 'add' ? (
						<input
							name="groupName"
							className="input input--med"
							type="text"
							value={groupName}
							onChange={this.handleChange}
						/>
					) : (
						<div className="input input--med">{user && split(user.xEmail, ':')[0]}</div>
					)}
				</div>
				<div className="form__group">
					<div className="form__group__header">
						<span className="form__group__label">User Name</span>
					</div>
					{this.props.type === 'add' ? (
						<input
							name="userName"
							className="input input--med"
							type="text"
							value={userName}
							onChange={this.handleChange}
						/>
					) : (
						<div className="input input--med">{user && split(user.xEmail, ':')[1]}</div>
					)}
				</div>
			</div>
		);
	};

	renderEmail = () => {
		const { emails } = this.state;
		const { user } = this.props;

		return (
			<div className="form__group">
				<div className="form__group__header">
					<span className="form__group__label">Email</span>
					<span className="form__group__required" data-tooltip="Required">
						*
					</span>
					{this.props.type === 'add' ? (
						<i
							className="icon icon--tny icon--regular--info spc--left--tny datatooltip--w--150"
							data-tooltip="A maximum of 10 emails can be added."
						></i>
					) : null}
				</div>
				{this.props.type === 'add' ? (
					<React.Fragment>
						<ReactMultiEmail
							placeholder="Email"
							emails={emails}
							onChange={this.setEmail}
							validateEmail={isEmail}
							getLabel={this.getLabel}
							disabled={size(emails) >= 10}
						/>
						{size(emails) >= 10 ? (
							<div className="message message--sml message--default spc--top--sml fullwidth">
								A maximum of 10 email addresses can be added.
							</div>
						) : (
							''
						)}
					</React.Fragment>
				) : (
					<div className="input input--med">{user && user.xEmail}</div>
				)}
			</div>
		);
	};

	renderCustomUser = () => {
		const selectAll = every(this.state.permissions, value => value);
		return (
			<div>
				<div className="user__permissions">
					<div className="group spc--bottom--sml">
						<div className="pull">
							Permissions{' '}
							<span className="form__group__required" data-tooltip="Required">
								*
							</span>
						</div>
						<div className="push">
							<input
								type="checkbox"
								className="input input--check"
								id="select all"
								name="select all"
								value="select all"
								checked={selectAll}
								onChange={() => this.checkAll(!selectAll)}
							/>
							<label className="type--none" htmlFor="select all">
								Select All
							</label>
						</div>
					</div>
					<div className="user__permissions__holder">
						{map(permissions, ({ key, label }) => {
							return (
								<div key={key} className="user__permissions__item">
									<input
										type="checkbox"
										className="input input--check"
										id={key}
										name={key}
										value={key}
										checked={this.state.permissions[key]}
										onChange={this.checkPermission}
									/>
									<label htmlFor={key}>{label}</label>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	};

	renderTabs = () => {
		if (this.props.type === 'edit' || this.state.emailBlocked) return null;
		const { isEmailTab } = this.state;
		return (
			<ul className="tabs spc--bottom--lrg">
				<li className="tabs__item">
					<a
						href="javascript:void(0)"
						onClick={() => this.switchTab(true)}
						className={`tabs__link ${isEmailTab && 'is-active'}`}
					>
						Email User
					</a>
				</li>
				<li className="tabs__item">
					<a
						href="javascript:void(0)"
						onClick={() => this.switchTab(false)}
						className={`tabs__link ${!isEmailTab && 'is-active'}`}
					>
						Single Sign On
					</a>
				</li>
			</ul>
		);
	};

	render() {
		const { children, className, type, shouldHideModal, user, isLoading } = this.props;
		const { isModalOpen, emails, isValidInput, role, inputValue, isEmailTab, groupName } = this.state;
		const title = type === 'add' ? 'Add a new user' : 'Edit User';
		const emailHasValue = (!isEmpty(emails) && !inputValue) || isValidInput;

		return (
			<React.Fragment>
				<button onClick={this.handleOpenModal} type="button" className={className}>
					{children}
				</button>
				<Modal
					isOpen={isModalOpen}
					onClose={this.handleCloseModal}
					overlayClassName={shouldHideModal() ? 'modal__hide' : 'modal__overlay'}
				>
					<div className="modal__header">
						<h4 className="modal__header__title">{title}</h4>
					</div>

					<div className="modal__body">
						{this.renderTabs()}
						{isEmailTab ? this.renderEmail() : this.renderUserGroup()}
						{this.renderRoles()}
					</div>

					<div className="modal__footer">
						<button
							type="button"
							tabIndex="-1"
							className="btn btn--med btn--primary"
							disabled={
								(!user && ((isEmailTab && !emailHasValue) || (!isEmailTab && !groupName))) || !role || isLoading
							}
							onClick={type === 'add' ? this.createNewUser : this.saveChanges}
						>
							{type === 'add' ? 'Create and Invite User' : 'Save'}
						</button>
					</div>
				</Modal>
			</React.Fragment>
		);
	}
}

AddEditUser.defaultProps = {
	type: 'edit',
};

AddEditUser.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
	type: PropTypes.oneOf(['add', 'edit']).isRequired,
	user: PropTypes.object,
	saveChanges: PropTypes.func,
	createNewUser: PropTypes.func,
	shouldHideModal: PropTypes.func,
	mapResponseToPermissions: PropTypes.func,
	handleError: PropTypes.func,
	isLoading: PropTypes.bool.isRequired,
};

export default withError(AddEditUser);
